import { ChevronLeft } from '@cfa-icons/system';
import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import MemberInfoSection from 'app/components/shared/CardRoleGroups/MemberInfoSection';
import { IdentityUser } from 'app/types/IdentityUser';
import { EmployeeImageData } from 'app/types/ImageTypes';
import Member from 'app/types/Member';
import MemberStatus from 'app/types/MemberStatus';
import MemberType from 'app/types/MemberType';
import useUsers from 'app/utils/hooks/useUsers';
import { titleCase } from 'app/utils/namingFormat';
import {
  Breadcrumbs,
  Divider,
  Icon,
  List,
  Typography,
} from 'cfa-react-components';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';

const CONTRACTOR_AUDIENCE = 'CONTRACTOR_AUDIENCE';

const CompanyPage: React.FC = () => {
  const { companyName } = useParams<{ companyName?: string }>();
  const [userImageData, setUserImageData] = useState<EmployeeImageData[]>([]);
  const navigate = useNavigate();
  const user = useUsers();

  const filteredUsers = useMemo(() => {
    return user.data.filter(member => {
      const isExternalWorker = member.AUDIENCE_TAG === CONTRACTOR_AUDIENCE;
      const matchesCompany = !companyName || member.company === companyName;
      return isExternalWorker && matchesCompany;
    });
  }, [user.data, companyName]);

  const mapToMember = (identityUser: IdentityUser): Member => {
    //helper to convert the status from the IdentityUser to a MemberStatus
    const safeMemberStatus = (status: string | undefined): MemberStatus => {
      return MemberStatus.ACTIVE;
    };

    //helper except for the type property and MemberType enum
    const safeMemberType = (type: string | undefined): MemberType => {
      return MemberType.CONTRACTOR;
    };

    return {
      userId: identityUser.GUID,
      groupId: identityUser.departmentID || 'defaultGroupId',
      type: safeMemberType(identityUser.userType),
      name: identityUser.displayName || identityUser.cfaUsername || 'Unknown',
      status: safeMemberStatus(identityUser.status),
    } as Member;
  };
  return user ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <div className="navigation-breadcrumbs-wrapper">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon icon={ChevronLeft} className="info" size="sm" />
              <Breadcrumbs
                breadcrumbs={[
                  {
                    label: 'Back',
                    onClick: () => {
                      window.history.back();
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
            data-cy="external-company"
          >
            {companyName ? titleCase(companyName)?.replace(/[()]/g, '') : ''}
          </Typography>
        </div>
        <div
          style={{
            width: '800px',
            margin: '0 auto',
            marginTop: '20px',
          }}
        >
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <div>
            <List
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gridTemplateColumns: 'repeat(4, 1fr)',
                justifyContent: 'center',
                alignItems: 'stretch',
              }}
            >
              {filteredUsers.map((member, index) => {
                const mappedMember = mapToMember(member);
                return (
                  <div
                    key={member.GUID}
                    style={{
                      flexBasis: '20%',
                      minWidth: '150px',
                    }}
                    data-cy="external-profile"
                  >
                    <MemberInfoSection
                      member={mappedMember}
                      index={index}
                      setUserImageData={setUserImageData}
                      hideRole={true}
                    />
                  </div>
                );
              })}
            </List>
          </div>
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
            }}
          ></div>
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <PageNotFound></PageNotFound>
  );
};

export default CompanyPage;
