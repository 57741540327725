import { useAppSelector } from 'app/store';
import { selectAllTags } from 'app/store/tags/selectors';
import Tag from 'app/types/Tag';
import { Checkbox, Col, Multiselect } from 'cfa-react-components';
import CreateTagButton from '../FormDialog/CreateTag';
import './EditGroupTags.scss';

const renderCheckBoxMultiselect = (option: string, selectedTags: string[]) => {
  return (
    <>
      <Checkbox
        checked={selectedTags?.includes(option)}
        label={option}
        crossOrigin={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        style={{ padding: '10px 0', pointerEvents: 'none' }}
      />
    </>
  );
};

type EditTagsProps = {
  selectedTags: string[];
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
};

export const EditTags = ({ selectedTags, setSelectedTags }: EditTagsProps) => {
  const tagList = useAppSelector(selectAllTags);
  const tagNames = extractTagNames(tagList);

  function extractTagNames(tags: Tag[]): string[] {
    return tags.map(tag => tag.tagName);
  }

  return (
    <Col>
      <Multiselect
        label="Tags"
        onChange={selected => {
          setSelectedTags(selected);
        }}
        options={tagNames}
        placeholder={selectedTags?.length === 0 ? 'None' : ''}
        value={selectedTags}
        fullWidth
        color="secondary"
        data-cy="tags-field"
        renderOption={option => renderCheckBoxMultiselect(option, selectedTags)}
      />
      <CreateTagButton
        setSelectedTags={setSelectedTags}
        selectedTags={selectedTags}
      />
    </Col>
  );
};
