import { IconDots } from '@tabler/icons-react';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import { changeRole, removeRole } from 'app/store/roles/actions';
import Role from 'app/types/Role';
import { adminAccess } from 'app/utils/hasPermissions/allowFeature';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Container,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
  Tooltip,
} from 'cfa-react-components';
import React, { useState } from 'react';
import { DeleteObjectModal } from './DeleteObjectModal';

export const EditRole = ({ role }: { role: Role }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [updatedRoleName, setUpdatedRoleName] = useState<string>(role.name);
  const [updatedRank, setUpdatedRank] = useState<string>(role.rank);
  const [errorText, setErrorText] = useState('');
  const allowDeleteRole: boolean = adminAccess();
  // Sets the open state to true when a button is clicked.
  const handleClickOpen = () => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    setOpen(true);
  };

  // Sets the open state to false
  // Sets role state to roleName
  const isInvalidRank = (str1: string): boolean => {
    const num1 = parseInt(str1, 10);
    if (isNaN(num1)) {
      return true;
    }

    return num1 < 0 || num1 > 100;
  };

  const handleClose = () => {
    setOpen(false);
    setUpdatedRoleName(role.name);
    setUpdatedRank(role.rank);
    setErrorText('');
  };

  const handleSaveClose = () => {
    if (updatedRank == '' || updatedRank == undefined) {
      setErrorText('Field required');
      return;
    }
    if (isInvalidRank(updatedRank)) {
      setErrorText('Rank must be between 0 and 100.');
      return;
    }

    dispatch(changeRole(role.id, updatedRoleName, updatedRank))
      .then(() => {
        setOpen(false);
        setErrorText('');
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.editRole, role.id);
      });
  };

  const handleDelete = () => {
    dispatch(removeRole(role.id))
      .then(() => {
        setOpen(false);
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.deleteRole, role.id);
      });
  };

  return (
    <div>
      <Tooltip content="Edit" key={`role-${role.name}`}>
        <IconButton
          size="sm"
          color="secondary"
          onClick={handleClickOpen}
          data-cy="edit-role-btn"
        >
          <Icon className="edit_icon" icon={IconDots}></Icon>
        </IconButton>
      </Tooltip>

      <Modal
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
        disableOverlayClick={true}
      >
        <React.Fragment key={`role-${role.id}`}>
          <ModalHeader>Edit Role</ModalHeader>
          <ModalBody>
            To edit the selected role, enter a new role name. To delete the
            selected role entirely, click “Delete Role”.
            <Container style={{ textAlign: 'left', padding: 20 }}>
              <React.Fragment key=".0">
                <TextField
                  label="Role"
                  required
                  onChange={e => setUpdatedRoleName(e.target.value)}
                  value={updatedRoleName}
                  placeholder="Enter a new role name"
                  fullWidth
                  data-cy="edit-role-in"
                />
              </React.Fragment>
              <React.Fragment key=".1">
                <TextField
                  errorText={errorText}
                  label="Rank"
                  required
                  onChange={e => setUpdatedRank(e.target.value)}
                  value={
                    updatedRank !== undefined ? updatedRank.toString() : '100'
                  }
                  placeholder="Enter a rank value [0, 100]"
                  fullWidth
                  data-cy="edit-rank-in"
                />
              </React.Fragment>
            </Container>
          </ModalBody>
          <ModalFooter>
            <DeleteObjectModal
              itemName={role.name}
              handleDelete={handleDelete}
              handleClose={handleClose}
              allowDelete={allowDeleteRole}
              type="Role"
            />
            <Button
              onClick={handleSaveClose}
              variant="filled"
              data-cy="put-role-btn"
              size="lg"
            >
              Edit Role
            </Button>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    </div>
  );
};
export default EditRole;
