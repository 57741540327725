import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { Divider, Typography } from 'cfa-react-components';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CompanyNameSearchSection } from './CompanyNameSearchSection';
import { GuildSearchSection } from './GuildsSearchSection';
import { LeadershipSearchSection } from './LeadershipSearchSection';
import { PeopleSearchPicture } from './PeopleSearchPicture';
import { PeopleSearchSection } from './PeopleSearchSection';
import { PodSearchSection } from './PodSearchSection';
import { PointOfContactRoleSearchSection } from './PointOfContactRoleSearchSection';
import { RoleSearchSection } from './RoleSearchSection';
import './SearchPage.scss';
import SearchSectionWrapper from './SearchSectionStyling';

export const SearchPage = () => {
  const [search, setSearch] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const q = params.get('q');
    if (q) {
      setSearch(q);
    }
  }, [location]);

  return (
    <>
      <Navigation />
      <div className="main-container">
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            Search Results
          </Typography>
        </div>

        <div>
          <Typography
            variant="h2"
            fontWeight="bold"
            align="left"
            gutterBottom
            style={{
              marginLeft: '80px',
              color: '#5B6770',
            }}
          >
            You searched for "{search}"
          </Typography>
          <Divider orientation="horizontal" variant="middle" inset={80} />
        </div>
        <br></br>
        <SearchSectionWrapper>
          <PeopleSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <PodSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <LeadershipSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <GuildSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <RoleSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <PointOfContactRoleSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <CompanyNameSearchSection search={search} />
        </SearchSectionWrapper>
      </div>
      <Footer />
    </>
  );
};

export default SearchPage;

export type SearchSectionProps = {
  search: string;
};

function collapseList<T>(list: T[]): T[] {
  return list.slice(0, 5);
}

type ResultsProps<T> = {
  results: T[];
  collapse: boolean;
  navigation?: (id: string) => void;
  showPicture?: boolean;
};

type SharedProps = {
  id?: string;
  name?: string;
  userId?: string;
};

const StyledResultsContainer = styled.div<{ showPicture?: boolean }>`
  display: ${props => (props.showPicture ? 'flex' : 'block')};
  flex-direction: ${props => (props.showPicture ? 'row' : 'column')};
  flex-wrap: wrap;
  padding-left: ${props => (props.showPicture ? '8vh' : '0px')};
`;

export const Results = <T extends SharedProps>({
  results,
  collapse,
  navigation,
  showPicture,
}: ResultsProps<T>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchQuery = params.get('q') || '';

  const checkUserNavigate = (item: T) => {
    if (navigation) {
      if (item.userId) {
        navigation(item.userId);
      }
      if (item.id) {
        navigation(item.id!);
      } else {
        navigation(item.name!);
      }
    }
  };

  return (
    <>
      <div className="search-results">
        {collapse ? (
          <StyledResultsContainer showPicture={showPicture}>
            {collapseList<T>(results).map(item => (
              <React.Fragment key={item.id ?? item.userId}>
                {showPicture ? (
                  <PeopleSearchPicture result={item} />
                ) : (
                  <Typography
                    onClick={() => checkUserNavigate(item)}
                    variant="h4"
                    color="secondary"
                    fontWeight="medium"
                    align="left"
                    data-cy="search-results"
                    style={{
                      marginLeft: '10vh',
                      cursor: 'pointer',
                    }}
                  >
                    {item.name}
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </StyledResultsContainer>
        ) : (
          <StyledResultsContainer showPicture={showPicture}>
            {results.map(item => (
              <React.Fragment key={item.id ?? item.userId}>
                {showPicture ? (
                  <PeopleSearchPicture
                    result={item}
                    style={{
                      width: 'calc(20% - 10px)',
                      margin: '5px',
                      boxSizing: 'border-box',
                    }}
                  />
                ) : (
                  <Typography
                    onClick={() => checkUserNavigate(item)}
                    variant="h4"
                    color="secondary"
                    fontWeight="medium"
                    align="left"
                    data-cy="search-results"
                    style={{
                      marginLeft: '7vh',
                      cursor: 'pointer',
                    }}
                  >
                    {item.name}
                  </Typography>
                )}
              </React.Fragment>
            ))}
            {results.length === 0 && (
              <Typography
                variant="h4"
                fontWeight="medium"
                align="left"
                style={{
                  color: 'grey',
                  marginLeft: '7vh',
                }}
              >
                No Results
              </Typography>
            )}
          </StyledResultsContainer>
        )}
      </div>
    </>
  );
};

type SearchHeaderProps = {
  title: string;
  size: number;
  collapse: boolean;
  setCollapse: (collapse: boolean) => void;
};
export const SearchHeader = ({
  title,
  size,
  collapse,
  setCollapse,
}: SearchHeaderProps) => {
  return (
    <>
      <div className="search-result-header">
        <Typography
          variant="subtitle2"
          color="primary"
          fontWeight="bold"
          align="left"
        >
          {title}
        </Typography>
        {size > 4 ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
              onClick={() => setCollapse(!collapse)}
            >
              <Typography
                variant="caption1"
                color="secondary"
                fontWeight="medium"
                align="right"
                style={{
                  marginTop: '4px',
                  marginRight: '4px',
                  cursor: 'pointer',
                }}
              >
                {collapse ? 'View All' : 'Collapse'}
              </Typography>
              <Typography
                variant="body2"
                fontWeight="regular"
                align="right"
                style={{
                  color: '#80878C',
                  marginBottom: '-12px',
                }}
              >
                ({size}) Results
              </Typography>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export const LineBreak = () => {
  return (
    <hr
      style={{
        border: 'none',
        height: '1px', // Adjust the thickness of the line
        background: 'linear-gradient(to right, red 25%, grey 25%)', // Gradient from red to grey
        marginLeft: '80px',
        marginRight: '80px',
      }}
    />
  );
};
