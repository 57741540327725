import { ChevronLeft } from '@cfa-icons/system';
import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { useAppDispatch, useAppSelector } from 'app/store';
// import { setBreadcrumbAction } from 'app/store/breadcrumbs/actions';
import { BreadcrumbActions } from 'app/store/breadcrumbs/BreadcrumbClickMapper';
import {
  popBreadcrumbAction,
  pushBreadcrumbAction,
  setIsNavigatingBackAction,
} from 'app/store/breadcrumbs/actions';
import {
  breadcrumbExists,
  buildBreadcrumbsFromStore,
} from 'app/store/breadcrumbs/breadcrumbHelper';
import { selectIsNavigatingBack } from 'app/store/breadcrumbs/selectors';
import { selectGuildByID } from 'app/store/guilds/selectors';
import { allowEditGuild } from 'app/utils/hasPermissions/allowFeature';
import {
  Breadcrumbs,
  Button,
  Icon,
  LoadingIndicator,
  Tooltip,
  Typography,
} from 'cfa-react-components';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { handleBackToSearchResults } from '../../utils/breadcrumbSessionStorage';
import './Guilds.scss';

export const GuildProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { guildId } = useParams() as { guildId: string };

  const guild = useAppSelector(selectGuildByID(guildId));
  const breadcrumbs = buildBreadcrumbsFromStore(
    dispatch,
    navigate,
    location.pathname,
  );
  const allowGuildEdit: boolean = allowEditGuild();
  const fromSearch = sessionStorage.getItem('fromSearch');
  const isNavigatingBack = useAppSelector(selectIsNavigatingBack);

  const editGuilds = () => {
    navigate(`/guilds/${guild?.id}/edit`);
  };

  useEffect(() => {
    // using session storage to see where user comes from
    if (fromSearch === 'true') {
      handleBackToSearchResults(dispatch, navigate);
    } else {
      if (isNavigatingBack) {
        return; // Exit if pod is not yet loaded or navigating back
      } else {
        const guildsBreadcrumb = {
          label: guild?.name || '',
          action: BreadcrumbActions.GuildProfile,
          entityId: guildId,
        };
        if (!breadcrumbExists(guildsBreadcrumb, breadcrumbs)) {
          dispatch(pushBreadcrumbAction(guildsBreadcrumb));
        }
      }
    }
  }, []);

  const handleBackClick = () => {
    dispatch(setIsNavigatingBackAction(true)); // Set the flag
    dispatch(popBreadcrumbAction());
    window.history.back(); // Go back to the previous page

    // Clear the flag after a short delay to allow the component to re-render
    setTimeout(() => {
      dispatch(setIsNavigatingBackAction(false));
    }, 0);
  };

  return guild ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <div className="navigation-breadcrumbs-wrapper">
            {fromSearch && (
              <>
                <Icon icon={ChevronLeft} className="info" size="sm" />
              </>
            )}
            {breadcrumbs.length > 3 ? (
              <>
                <Icon icon={ChevronLeft} className="info" size="sm" />
                <Breadcrumbs
                  breadcrumbs={[
                    {
                      label: 'Back',
                      onClick: handleBackClick,
                    },
                  ]}
                />
              </>
            ) : (
              <Breadcrumbs maxItems={4} breadcrumbs={breadcrumbs} />
            )}
          </div>
          {allowGuildEdit ? (
            <Button
              color="primary"
              onClick={editGuilds}
              size="md"
              variant="outlined"
              style={{
                justifySelf: 'end',
              }}
              disabled={!allowGuildEdit}
              data-cy="edit-guild-btn"
            >
              Edit Guild
            </Button>
          ) : (
            <Tooltip content="Insufficient Permissions">
              <span>
                <Button
                  color="primary"
                  onClick={editGuilds}
                  size="md"
                  variant="outlined"
                  style={{
                    justifySelf: 'end',
                  }}
                  disabled={!allowGuildEdit}
                  data-cy="edit-guild-btn"
                >
                  Edit Guild
                </Button>
              </span>
            </Tooltip>
          )}
        </div>
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
            style={{
              marginLeft: '85px',
              marginRight: '85px',
            }}
          >
            {guild.name}
          </Typography>
        </div>
        <Typography
          variant="body1"
          color="secondary"
          fontWeight="medium"
          align="center"
          gutterBottom
          style={{
            minHeight: '50vh',
            marginTop: '30px',
            marginLeft: '85px',
            marginRight: '85px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {guild.info}
        </Typography>
      </div>
      <Footer />
    </>
  ) : (
    <LoadingIndicator variant={'page'}></LoadingIndicator>
  );
};
