import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { podsAdapter } from './slice';

export const selectPodReducerState = (store: RootState) => store.pods;

const podSelectors = podsAdapter.getSelectors(selectPodReducerState);

export const selectPods = (store: RootState) => podSelectors.selectAll(store);
export const selectPodsBySubDeptID = (subdeptId: string) =>
  createSelector([selectPods], pods =>
    pods.filter(pod => pod.deptId.localeCompare(subdeptId) === 0),
  );

export const selectPodById = (podId: string) => (store: RootState) =>
  podSelectors.selectById(store, podId);

export const selectPodMembersByPodId =
  (podId: string) => (store: RootState) => {
    const pod = podSelectors.selectById(store, podId);
    if (pod) {
      return pod.members;
    }
    return undefined;
  };

export const selectPodsByTag = (tag: string) => (store: RootState) => {
  return podSelectors.selectAll(store).filter(pod => pod.tags?.includes(tag));
};

export const selectLoadPodStatus = (store: RootState) =>
  store.pods.apiStatus.loadPods;
