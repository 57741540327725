import { Download } from '@cfa-icons/system';
import { ACTIONS } from 'app/constants/UserMessaging';
import capacityApi from 'app/services/CapacityApi';
import {
  displayErrorToast,
  displaySuccessToast,
} from 'app/utils/toasts/displayToast';
import { Button, Icon, Typography } from 'cfa-react-components';

export const ExportDataButton = () => {
  const handleExportButtonClick = async () => {
    try {
      capacityApi.capacity.exportPodData().then(data => {
        if (data.status === 'success') {
          displaySuccessToast('', ACTIONS.exportPodsData);
          window.open(data.access_url, '_blank');
        } else {
          displayErrorToast(data.message, ACTIONS.exportPodsData);
        }
      });
    } catch (error: unknown) {
      let message: string = 'an unexpected error occurred.';
      if (error instanceof Error) {
        message = error.message;
      }
      console.error('Error calling capacity API: ', message);
      displayErrorToast(message, ACTIONS.exportPodsData);
    }
  };

  return (
    <Button
      onClick={handleExportButtonClick}
      variant="outlined"
      data-cy="export-data-btn"
      size="md"
      color={'secondary'}
    >
      <Typography
        variant="body1"
        color={'secondary'}
        style={{ marginRight: '10px' }}
      >
        Export DTT Teamview Data
      </Typography>
      <Icon icon={Download} color={'secondary'} size="sm" />
    </Button>
  );
};
export default ExportDataButton;
