import { Tag } from 'cfa-react-components';
import { useNavigate } from 'react-router-dom';

export type CardTagProps = {
  tag: string;
};

export const CardTag = ({ tag }: CardTagProps) => {
  const navigate = useNavigate();

  const handleTagClick = (tag: string) => {
    navigate(`/tags/${encodeURIComponent(tag)}`);
  };

  return (
    <div
      className="card-tag"
      style={{
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingRight: '5px',
        display: 'inline-flex',
      }}
    >
      <Tag
        variant="filled"
        color="pink"
        label={tag}
        data-cy="displayed"
        onClick={() => handleTagClick(tag)}
      />
    </div>
  );
};
