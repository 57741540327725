import { ACTIONS } from 'app/constants/UserMessaging';
import memberApi from 'app/services/MembersApi';
import podsApi from 'app/services/PodsApi';
import IdentityUser from 'app/types/IdentityUser';
import Member from 'app/types/Member';
import MemberStatus from 'app/types/MemberStatus';
import MemberType from 'app/types/MemberType';
import Pod from 'app/types/Pod';
import Role from 'app/types/Role';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import { AppAction } from '..';
import {
  addPod,
  deletePod,
  reloadPodAction,
  setPodsError,
  setPodsRequest,
  setPodsSuccess,
  updatePod as updatePodSlice,
} from './slice';

export const loadPods = (): AppAction<Promise<void>> => dispatch => {
  dispatch(setPodsRequest());
  return podsApi.pods
    .getAllPods()
    .then(async resp => {
      const pods = [] as Pod[];
      await Promise.all(
        resp.map(
          async pod =>
            await memberApi.members
              .getMembersByGroup(MemberType.PODS, pod.id)
              .then(data => {
                pod = { ...pod, members: data };
                pods.push(pod);
              }),
        ),
      );
      dispatch(setPodsSuccess(pods));
    })
    .catch(error => {
      displayErrorToast(error.message, ACTIONS.loadPods);
      dispatch(setPodsError(error));
    });
};

export const createPod =
  (
    info: string,
    podName: string,
    deptId: string,
    members: Member[],
    tags: string[],
  ): AppAction<Promise<void>> =>
  dispatch => {
    return podsApi.pods
      .createPod(podName, deptId as string, info, tags)
      .then(async response => {
        let tempMembers: Member[] = [];
        members.forEach(member => (member.groupId = response.id));
        await Promise.all(
          members.map(async member => {
            return await memberApi.members.createMember(
              {
                userId: member.userId,
                groupId: member.groupId,
                type: member.type,
                name: member.name,
                isContingent: member.isContingent as boolean,
                status: member.status,
              },
              member.roleId,
            );
          }),
        ).then(resp => {
          resp.forEach(member => tempMembers.push(member));
        });
        const newPod: Pod = { ...response, members: tempMembers };
        dispatch(addPod(newPod));
      });
  };

export const createPodMembers =
  (
    users: IdentityUser[],
    pod: Pod,
    roles?: Role[],
    updateState?: boolean,
  ): AppAction<Promise<void>> =>
  dispatch => {
    return Promise.all(
      users.map(async (user, index) => {
        const roleId = roles && roles[index] && roles[index].id;
        return await memberApi.members
          .createMember(
            {
              userId: user.GUID,
              groupId: pod.id,
              type: MemberType.PODS,
              name: user.displayName as string,
              isContingent: user.AUDIENCE_TAG === 'CONTRACTOR_AUDIENCE',
              status: MemberStatus.ACTIVE,
            },
            roleId,
          )
          .catch(e => {
            displayErrorToast(e.message, ACTIONS.createMember, user.GUID);
          });
      }),
    )
      .then(members => {
        const tempMembers: Member[] = pod.members ? [...pod.members] : [];
        members.forEach(member => {
          if (member === undefined) {
            return;
          }
          tempMembers.push(member);
        });
        const tempPod: Pod = { ...pod, members: tempMembers };
        if (updateState) {
          dispatch(updatePodSlice({ id: pod.id, changes: tempPod }));
        }
      })
      .catch(error => {
        throw error;
      });
  };

export const updatePod =
  (
    pod: Pod,
    newName: string,
    newInfo: string,
    newDeptId: string,
    newTags: string[],
  ): AppAction<Promise<void>> =>
  dispatch => {
    return podsApi.pods
      .updatePod(pod.id, newName, newInfo, newDeptId, newTags)
      .then(resp => {
        dispatch(updatePodSlice({ id: pod.id, changes: resp }));
      });
  };

export const updatePodMembers =
  (
    pod: Pod,
    members: Member[],
    newRoles?: Role[],
    updateState?: boolean,
  ): AppAction<Promise<void>> =>
  dispatch => {
    return Promise.all(
      members.map(async (member, index) => {
        const role = newRoles && newRoles[index];
        return await memberApi.members
          .updateMember(
            member.userId,
            member.groupId,
            member.type,
            role?.id,
            member.startDate,
            member.endDate,
            member.status,
          )
          .catch(error => {
            displayErrorToast(error.message, ACTIONS.editMember, member.userId);
            // throw error;
          });
      }),
    )
      .then(members => {
        const tempMembers: Member[] = pod.members ? [...pod.members] : [];
        members.forEach(member => {
          if (member === undefined) {
            return;
          }
          const index = tempMembers.findIndex(
            a => a.userId.localeCompare(member.userId) === 0,
          );
          tempMembers[index] = member;
        });
        const tempPod: Pod = { ...pod, members: tempMembers };
        if (updateState) {
          dispatch(updatePodSlice({ id: pod.id, changes: tempPod }));
        }
      })
      .catch(error => {
        throw error;
      });
  };

export const removePod =
  (podEntityId: string): AppAction<Promise<void>> =>
  dispatch => {
    return podsApi.pods.deletePod(podEntityId).then(resp => {
      dispatch(deletePod(podEntityId));
    });
  };

export const removePodMembers =
  (
    pod: Pod,
    memberIds: string[],
    updateState?: boolean,
  ): AppAction<Promise<void>> =>
  dispatch => {
    let tempMembers: Member[] = [...pod.members!];
    return Promise.all(
      memberIds.map(async (memberId, index) => {
        return await memberApi.members
          .deleteMember(pod.id, memberId)
          .then(resp => {
            const index = tempMembers.findIndex(a => a.userId === memberId);
            tempMembers.splice(index, 1);
          })
          .catch(e => {
            displayErrorToast(e.message, ACTIONS.deleteMember, memberId);
          });
      }),
    )
      .then(_ => {
        const tempPod: Pod = { ...pod, members: tempMembers };
        if (updateState) {
          dispatch(updatePodSlice({ id: pod.id, changes: tempPod }));
        }
      })
      .catch(error => {
        throw error;
      });
  };

export const reloadPod =
  (id: string): AppAction<Promise<void>> =>
  dispatch => {
    return podsApi.pods
      .getPod(id)
      .then(async resp => {
        await memberApi.members
          .getMembersByGroup(MemberType.PODS, resp.id)
          .then(data => {
            resp = { ...resp, members: data };
            dispatch(reloadPodAction(resp));
          })
          .catch(error => {
            console.log('Failed to get Pod Members', error);
          });
      })
      .catch(error => {
        console.log('Failed to get Pod', error);
      });
  };
