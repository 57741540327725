import { useAppSelector } from 'app/store';
import { selectRoleById } from 'app/store/roles/selectors';
import { selectUserById } from 'app/store/users/selectors';
import { EmployeeImageData } from 'app/types/ImageTypes';
import Member from 'app/types/Member';
import { titleCase } from 'app/utils/namingFormat';
import { Card, CardContent, Typography } from 'cfa-react-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EmployeePicture from '../EmployeePicture';

export type MemberInfoSectionProps = {
  member: Member;
  index: number;
  setUserImageData?: React.Dispatch<React.SetStateAction<EmployeeImageData[]>>;
  hideRole?: boolean;
};
export const MemberInfoSection = ({
  member,
  index,
  hideRole = false,
  setUserImageData,
}: MemberInfoSectionProps) => {
  const navigate = useNavigate();
  const user = useAppSelector(selectUserById(member.userId));
  const role = useAppSelector(selectRoleById(member.roleId!));

  const handleClick = () => {
    navigate(`/profile/${user?.GUID}`);
  };

  useEffect(() => {
    if (setUserImageData) {
      if (user != undefined) {
        const tempData: EmployeeImageData = {
          employeeNumber: user!.employeeNumber ?? 'NO_VALUE',
          name: user.displayName!,
          role: role,
          userType: user.userType,
        };
        setUserImageData(prev => [...prev, tempData]);
      }
    }
  }, [user]);

  return (
    <Card
      key={index}
      style={{
        margin: '15px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '135px',
      }}
      onClick={() => handleClick()}
      elevation={0}
    >
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <EmployeePicture user={user!} imgHeight="100px" imgWidth="100px" />
        </div>
        <div>
          <Typography
            style={{ paddingTop: '20px' }}
            data-cy={`member-name-${member.name}`}
          >
            {member.name}
          </Typography>

          {member.isContingent && user ? (
            <Typography data-cy="company-name">
              ({titleCase(user?.company)})
            </Typography>
          ) : null}

          <div style={{ padding: '0px' }}>
            <span
              style={{ fontStyle: 'italic', fontSize: 'smaller' }}
              data-cy={`role-member-${member.name}`}
            >
              {/* Empty role for external workers for now */}
              {hideRole ? '' : role?.name || 'Member'}
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default MemberInfoSection;
