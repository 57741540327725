import LeadershipCard from 'app/components/shared/LeadershipCard/LeadershipCard';
import PodCard from 'app/components/shared/PodCard/PodCard';
import { useAppSelector } from 'app/store';
import { selectDepartments } from 'app/store/dept/selectors';
import { selectLeadershipByTag } from 'app/store/leadership/selectors';
import { selectPodsByTag } from 'app/store/pods/selectors';
import { usePodRoles } from 'app/store/roles/selectors';
import Department from 'app/types/Department';
import {
  Divider,
  List,
  ListItem,
  Switch,
  Typography,
} from 'cfa-react-components';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Navigation from '../../components/Navigation/Navigation';
import './TagsPage.scss';

const TagsPage: React.FC = () => {
  const { tagName } = useParams<{ tagName: string }>();
  const pods = useAppSelector(selectPodsByTag(tagName || '')) || [];
  const leadershipTeams =
    useAppSelector(selectLeadershipByTag(tagName || '')) || [];
  const deptList: Department[] = useAppSelector(selectDepartments);

  const newRoleList = usePodRoles();
  const [staffOnly, setStaffOnly] = useState(false);

  return (
    <>
      <Navigation />
      <div className="tags-page-main-container">
        <Typography variant="h2" color="primary" data-cy="tags-heading">
          Tags
        </Typography>
        <div className="switch-container">
          <Switch
            data-cy="toggle"
            aria-label="Filter staff?"
            label="Staff Only"
            crossOrigin={undefined}
            checked={staffOnly}
            onChange={e => setStaffOnly(e.currentTarget.checked)}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            style={{ marginBottom: '7px', paddingRight: '50px' }}
          />
        </div>
        <Divider orientation="horizontal" variant="middle" />
        <Typography
          data-cy="pods-heading"
          variant="h3"
          color="secondary"
          className="text-left"
        >
          Pods
        </Typography>
        <Divider orientation="horizontal" variant="middle" />
        {pods.length > 0 ? (
          <List className="card-list-style">
            {pods
              .filter(
                pod =>
                  !staffOnly ||
                  pod.members.some(member => member.isContingent !== true),
              )
              .map(pod => (
                <ListItem key={pod.id} data-cy="pod-cards">
                  <PodCard
                    pod={pod}
                    staffOnly={staffOnly}
                    rolesList={newRoleList}
                    key={pod.id}
                  />
                </ListItem>
              ))}
          </List>
        ) : (
          <Typography
            data-cy="no-results"
            variant="h4"
            color="primary"
            className="text-left"
          >
            No Results
          </Typography>
        )}
        <Typography
          data-cy="lt-heading"
          variant="h3"
          color="secondary"
          className="text-left"
        >
          Leadership Teams
        </Typography>
        <Divider orientation="horizontal" variant="middle" />
        {leadershipTeams.length > 0 ? (
          <List className="card-list-style">
            {leadershipTeams.map(leadership => (
              <ListItem key={leadership.id} data-cy="lt-cards">
                <LeadershipCard
                  rolesList={newRoleList}
                  deptList={deptList}
                  leadership={leadership}
                  leadershipMembers={leadership.members || []}
                  onProfile={true}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            data-cy="no-results"
            variant="h4"
            color="primary"
            className="text-left"
          >
            No Results
          </Typography>
        )}
      </div>
      <Footer />
    </>
  );
};

export default TagsPage;
