import Capacity from 'app/types/Capacity';
import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

const apiClient = createApiClient(AppConstants.URL.CAPACITY);
const capacityApi = {
  capacity: {
    exportPodData: () => apiClient.post('').json<Capacity>(),
  },
};

export default capacityApi;
