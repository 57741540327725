import { AppDispatch } from 'app/store';
import { BreadcrumbActions } from 'app/store/breadcrumbs/BreadcrumbClickMapper';
import { NavigateFunction } from 'react-router-dom';
import { replaceBreadcrumbsAction } from '../store/breadcrumbs/actions';

export const handleBackToSearchResults = (
  dispatch: AppDispatch,
  navigate: NavigateFunction,
): void => {
  const searchQuery = sessionStorage.getItem('searchQuery') || '';

  dispatch(
    replaceBreadcrumbsAction([
      {
        label: 'Back to Search Results',
        action: BreadcrumbActions.Search,
        searchQuery: searchQuery,
        // onClick: () => {
        //   navigate(`/search?q=${searchQuery}`);
        // },
      },
    ]),
  );
};
