import { Container, Modal, ModalBody, ModalHeader } from 'cfa-react-components';
import React, { ReactNode } from 'react';

const FAQHeader = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        color: '#DD0031',
        fontWeight: 'bold',
        lineHeight: '2',
        fontFamily: 'Apercu',
      }}
    >
      {children}
    </div>
  );
};

const FAQContent = ({ children }: { children: ReactNode }) => {
  return <p style={{ textAlign: 'left', fontFamily: 'Apercu' }}>{children}</p>;
};

export const GuildFAQ = () => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button
        data-cy="faqs"
        style={{
          width: '40px',
          height: '40px',
          marginLeft: '10px',
          borderRadius: '50%',
          fontSize: '12px',
          background: '#F8C1B8',
          color: '#DD0031',
          border: 'none',
          fontFamily: 'Apercu',
        }}
        onClick={() => setOpen(true)}
      >
        FAQs
      </button>
      <Modal
        data-cy="close-faqs"
        show={open}
        onClose={handleClose}
        size="lg"
        scrollMode="modal-body"
        disableOverlayClick={true}
      >
        <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
          <React.Fragment key=".0">
            <ModalHeader>
              <div style={{ color: '#DD0031', fontFamily: 'Apercu' }}>
                GUILDS FAQ
              </div>
            </ModalHeader>
            <ModalBody
              style={{ paddingBottom: '20px', textAlign: 'left' }}
              onClick={handleClose}
            >
              <FAQHeader>
                Why does it say “Insufficient Permissions” when I hover over a
                disabled button?
              </FAQHeader>
              <FAQContent>
                You do not have the correct WAS permissions to perform that
                action.
              </FAQContent>
              <FAQHeader>How do I change my permission access?</FAQHeader>
              <FAQContent>
                Fill out a WAS Request in Service Gateway to add or remove
                permissions.
              </FAQContent>
              <FAQHeader>How do I create a Guild?</FAQHeader>
              <FAQContent>
                Click on the Create Guild button on the lower right side of the
                page. Add a guild name and a guild description (optional). Then,
                click the Create Guild button. You must have GUILDS_ADMIN WAS
                permissions to perform this action.
              </FAQContent>
              <FAQHeader>How do I view the Guild Profile?</FAQHeader>
              <FAQContent>
                Click on the guild card to open the corresponding guild's
                profile.
              </FAQContent>
              <FAQHeader>
                How do I edit the name and description of a Guild?{' '}
              </FAQHeader>
              <FAQContent>
                Open the guild’s profile and then click the Edit Guild button in
                the top right corner. From there, you can click on the pencils
                to the right of the text you wish to edit. Click the appropriate
                button in the top right corner to either cancel or save your
                changes. You must have GUILDS_ADMIN WAS permissions to perform
                this action.{' '}
              </FAQContent>
              <FAQHeader>How do I delete a Guild? </FAQHeader>
              <FAQContent>
                Open the guild’s profile and then click the Edit Guild button.
                This will bring you to the edit page where you can delete the
                guild by clicking the Delete Guild in the bottom right corner
                and typing in delete permanently. Not all types of logins have
                permission to perform this function. You must have GUILDS_ADMIN
                WAS permissions to perform this action.
              </FAQContent>
              <Container style={{ textAlign: 'left' }}></Container>
            </ModalBody>
          </React.Fragment>
        </div>
      </Modal>
    </>
  );
};

export default GuildFAQ;
