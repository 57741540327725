import { LeadershipTeam } from 'app/types/Leadership';
import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

const leadershipApiClient = createApiClient(AppConstants.URL.LEADERSHIP);

const leadershipApi = {
  leadership: {
    getAllLeadership: () =>
      leadershipApiClient.get('').json<LeadershipTeam[]>(),
    createLeadership: (
      name: string,
      info?: string,
      deptId?: string,
      tags?: string[],
    ) => {
      const params: {
        name: string;
        info?: string;
        deptId?: string;
        tags?: string[];
      } = {
        name: name,
      };

      if (info || info !== undefined) {
        params.info = info;
      }
      if (deptId) {
        params.deptId = deptId;
      }
      if (tags) {
        params.tags = tags;
      }
      return leadershipApiClient
        .post(``, { json: params })
        .json<LeadershipTeam>();
    },
    updateLeadership: (
      leadership_id: string,
      name?: string,
      info?: string,
      deptId?: string,
      tags?: string[],
    ) => {
      const params: {
        name?: string;
        info?: string;
        deptId?: string;
        tags?: string[];
      } = {};
      if (name) {
        params.name = name;
      }
      if (info || info !== undefined) {
        params.info = info;
      }
      if (deptId) {
        params.deptId = deptId;
      }
      if (tags) {
        params.tags = tags;
      }
      return leadershipApiClient
        .put(`${leadership_id}`, { json: params })
        .json<LeadershipTeam>();
    },
    deleteLeadership: (leadership_id: string) =>
      leadershipApiClient.delete(`${leadership_id}`).json(),
    getLeadership: (id: string) =>
      leadershipApiClient.get(`${id}`).json<LeadershipTeam>(),
  },
};

export default leadershipApi;
