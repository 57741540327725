import React, { useState } from 'react';

import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import { createGuild } from 'app/store/guilds/actions';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  TextField,
  Tooltip,
} from 'cfa-react-components';
import '../../../../App.scss';

type CreateGuildProps = {
  allowGuildCreate: boolean;
};

export const CreateGuildButton = ({ allowGuildCreate }: CreateGuildProps) => {
  const dispatch = useAppDispatch();
  const [openGuild, setOpenGuild] = useState(false);
  const [guildName, setGuildName] = useState('');
  const [guildDescription, setGuildDescription] = useState('');

  const handleCloseGuild = () => {
    setGuildName('');
    setGuildDescription('');
    setOpenGuild(false);
  };

  const handleSaveCloseGuild = () => {
    dispatch(createGuild(guildName, guildDescription))
      .then(() => {
        handleCloseGuild();
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.createGuild);
      });
  };

  return (
    <>
      {allowGuildCreate ? (
        <Button
          size="sm"
          onClick={e => setOpenGuild(true)}
          style={{
            height: '40px',
          }}
          disabled={!allowGuildCreate}
          data-cy="create-guild-btn"
        >
          Create Guild
        </Button>
      ) : (
        <Tooltip content="Insufficient Permissions">
          <span>
            <Button
              size="sm"
              onClick={e => setOpenGuild(true)}
              style={{
                height: '40px',
              }}
              disabled={!allowGuildCreate}
              data-cy="create-guild-btn"
            >
              Create Guild
            </Button>
          </span>
        </Tooltip>
      )}
      <Modal
        show={openGuild}
        onClose={handleCloseGuild}
        size="lg"
        scrollMode="modal-body"
        disableOverlayClick={true}
      >
        <React.Fragment key=".0">
          <ModalHeader>Create New Guild</ModalHeader>
          <ModalBody>
            <Container style={{ textAlign: 'left' }}>
              <Row
                style={{
                  marginBottom: '1rem',
                  marginTop: '2rem',
                }}
              >
                <Col sm={12}>
                  <TextField
                    label="Guild Name"
                    required
                    onChange={e => setGuildName(e.target.value)}
                    placeholder="Enter a new guild name"
                    fullWidth
                    value={guildName}
                    data-cy="guild-name-in"
                  />
                </Col>
              </Row>
              <Col sm={12}>
                <Row
                  style={{
                    marginBottom: '1rem',
                    marginTop: '2rem',
                  }}
                >
                  <TextField
                    label="Guild Description"
                    onChange={e => setGuildDescription(e.target.value)}
                    placeholder="Ex. General Description, Leaders, Links, etc."
                    fullWidth
                    value={guildDescription}
                    multiline
                    rows={8}
                    data-cy="guild-desc-in"
                  />
                </Row>
              </Col>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseGuild} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleSaveCloseGuild}
              variant="filled"
              disabled={!guildName}
              data-cy="create-guild-modal-btn"
            >
              Create Guild
            </Button>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    </>
  );
};

export default CreateGuildButton;
