import { Button } from 'cfa-react-components';
import { SaveCloseModal } from '../FormDialog/SaveCloseModal';
//

type SaveAndCloseButtonsProps = {
  saveUpdateGroup: () => void;
  allowEditGroup: boolean;
  handleCloseGroup: () => void;
  isDisabled: boolean;
};

export const SaveAndCloseButtons = ({
  saveUpdateGroup,
  allowEditGroup,
  handleCloseGroup,
  isDisabled,
}: SaveAndCloseButtonsProps) => {
  return (
    <div
      style={{
        marginTop: '50px',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <SaveCloseModal
        isDisabled={allowEditGroup}
        handleClose={handleCloseGroup}
        handleSave={saveUpdateGroup}
      />
      <SaveGroupButton
        saveUpdateGroup={saveUpdateGroup}
        allowEditGroup={isDisabled}
      />
    </div>
  );
};

type SaveButtonProps = {
  saveUpdateGroup: () => void;
  allowEditGroup: boolean;
};

const SaveGroupButton = ({
  saveUpdateGroup,
  allowEditGroup,
}: SaveButtonProps) => {
  return (
    <Button
      color="primary"
      onClick={saveUpdateGroup}
      size="md"
      variant="filled"
      disabled={allowEditGroup}
      style={{
        marginLeft: '5px',
      }}
      data-cy="group-edit-save-btn"
    >
      Save
    </Button>
    // </div>
  );
};
