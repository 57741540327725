import { BreadcrumbProps } from 'cfa-react-components';
import { NavigateFunction } from 'react-router-dom';
import { AppDispatch } from '..';
import {
  BreadcrumbActions,
  BreadcrumbClickMapper,
} from './BreadcrumbClickMapper';
import { TeamviewBreadcrumbProps } from './TeamviewBreadcrumbProps';
import { useBreadcrumbs } from './selectors';

function buildBreadcrumb(
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  pathName: string,
  label: string,
  action: BreadcrumbActions,
  entityId?: string,
  searchQuery?: string,
) {
  let breadcrumb: BreadcrumbProps = {
    label: label,
    onClick: () => {
      const fn = BreadcrumbClickMapper[action];
      if (typeof fn === 'function') {
        if (entityId) {
          (
            fn as (
              dispatch: AppDispatch,
              navigate: NavigateFunction,
              pathName: string,
              entityId: string,
            ) => void
          )(dispatch, navigate, pathName, (entityId = entityId));
        } else if (searchQuery) {
          (
            fn as (
              dispatch: AppDispatch,
              navigate: NavigateFunction,
              pathName: string,
              searchQuery: string,
            ) => void
          )(dispatch, navigate, pathName, (searchQuery = searchQuery));
        } else {
          (
            fn as (
              dispatch: AppDispatch,
              navigate: NavigateFunction,
              pathName: string,
            ) => void
          )(dispatch, navigate, pathName);
        }
      } else {
        console.error('Invalid function type in BreadcrumbClickMapper');
      }
    },
  };
  return breadcrumb;
}

export function buildBreadcrumbsFromStore(
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  pathName: string,
) {
  const { breadcrumbs } = useBreadcrumbs();
  const newBreadcrumbs: BreadcrumbProps[] = breadcrumbs.map(breadcrumb => {
    return buildBreadcrumb(
      dispatch,
      navigate,
      pathName,
      breadcrumb.label,
      breadcrumb.action,
      breadcrumb.entityId,
      breadcrumb.searchQuery,
    );
  });
  return newBreadcrumbs;
}

export function breadcrumbExists(
  breadcrumb: TeamviewBreadcrumbProps,
  breadcrumbs: BreadcrumbProps[],
) {
  if (breadcrumbs.length === 0) {
    return false;
  }
  return breadcrumb.label === breadcrumbs[breadcrumbs.length - 1].label;
}
