import { RootState } from '..';
import { tagAdapter } from './slice';

export const selectTagReducerState = (store: RootState) => store.tag;

const tagSelectors = tagAdapter.getSelectors(selectTagReducerState);

export const selectTagsByGroupkey = (groupKey: string) => (store: RootState) =>
  tagSelectors.selectById(store, groupKey);

export const selectAllTags = (store: RootState) =>
  tagSelectors.selectAll(store);
