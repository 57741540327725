import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appSlice from './app/slice';
import breadcrumbsSlice from './breadcrumbs/slice';
import deptSlice from './dept/slice';
import guildsSlice from './guilds/slice';
import leadershipSlice from './leadership/slice';
import podsSlice from './pods/slice';
import pointsOfContactSlice from './points_of_contact/slice';
import roleSlice from './roles/slice';
import tagSlice from './tags/slice';
import identitySlice from './users/slice';

const rootPersistorConfig = {
  key: 'root',
  storage,
  whitelist: [],
  blacklist: [deptSlice.name],
};

const deptPersistConfig = {
  key: deptSlice.name,
  storage,
  blacklist: [],
};

const podsPersistConfig = {
  key: podsSlice.name,
  storage,
  blackList: [],
};

const leadershipPersistConfig = {
  key: leadershipSlice.name,
  storage,
  blackList: [],
};

const pointsOfContactPersistConfig = {
  key: pointsOfContactSlice.name,
  storage,
  blackList: [],
};

const rolePersistConfig = {
  key: roleSlice.name,
  storage,
  blacklist: [],
};

const tagPersistConfig = {
  key: tagSlice.name,
  storage,
  blacklist: [],
};

const appPersistConfig = {
  key: appSlice.name,
  storage,
  blacklist: ['initialized'],
};
const identityPersistConfig = {
  key: identitySlice.name,
  storage,
  blacklist: [],
};
const guildsPersistConfig = {
  key: guildsSlice.name,
  storage,
  blacklist: [],
};

const breadcrumbsPersistConfig = {
  key: breadcrumbsSlice.name,
  storage,
  blacklist: [],
};

const persistedChildReducers = {
  [deptSlice.name]: persistReducer(deptPersistConfig, deptSlice.reducer),
  [roleSlice.name]: persistReducer(rolePersistConfig, roleSlice.reducer),
  [tagSlice.name]: persistReducer(tagPersistConfig, tagSlice.reducer),
  [podsSlice.name]: persistReducer(podsPersistConfig, podsSlice.reducer),
  [leadershipSlice.name]: persistReducer(
    leadershipPersistConfig,
    leadershipSlice.reducer,
  ),
  [pointsOfContactSlice.name]: persistReducer(
    pointsOfContactPersistConfig,
    pointsOfContactSlice.reducer,
  ),
  [appSlice.name]: persistReducer(appPersistConfig, appSlice.reducer),
  [identitySlice.name]: persistReducer(
    identityPersistConfig,
    identitySlice.reducer,
  ),
  [guildsSlice.name]: persistReducer(guildsPersistConfig, guildsSlice.reducer),
  [breadcrumbsSlice.name]: persistReducer(
    breadcrumbsPersistConfig,
    breadcrumbsSlice.reducer,
  ),
};

const unpersistedChildReducers = {};

const rootReducer = combineReducers({
  ...persistedChildReducers,
  ...unpersistedChildReducers,
});

const persistedReducer = persistReducer(rootPersistorConfig, rootReducer);

export default persistedReducer;
