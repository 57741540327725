import { Plus, UserPlus } from '@cfa-icons/system';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import { createPointOfContact } from 'app/store/points_of_contact/actions';
import { selectPointsOfContactByDeptId } from 'app/store/points_of_contact/selectors';
import Department from 'app/types/Department';
import IdentityUser from 'app/types/IdentityUser';
import Role from 'app/types/Role';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Col,
  Container,
  Divider,
  Dropdown,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Typography,
} from 'cfa-react-components';
import { useState } from 'react';
import UserDropdown from '../UserDropdown';

export type AddLeadershipListItemProps = {
  rolesList: Role[];
  currentDepartment: Department;
  allowAddPointOfContact: boolean;
};

export const AddPointOfContactListItem = ({
  rolesList,
  currentDepartment,
  allowAddPointOfContact,
}: AddLeadershipListItemProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState<Role | null>(null);
  const [user, setUser] = useState<IdentityUser | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const existingContacts = useAppSelector(
    selectPointsOfContactByDeptId(currentDepartment?.id),
  );

  const isDuplicate = (
    selectedUser: IdentityUser,
    selectedRole: Role | null,
  ) => {
    return existingContacts.some(
      contact => contact.userId === selectedUser.GUID,
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRole(null);
    setUser(null);
    setErrorMessage(null);
  };

  const handleSaveClose = () => {
    if (user && role && isDuplicate(user, role)) {
      setErrorMessage('Point of contact already exist for this subdepartment.');
      return;
    }

    dispatch(createPointOfContact(user!, currentDepartment, role!))
      .then(() => {
        handleClose();
      })
      .catch(error => {
        displayErrorToast(
          error.message,
          ACTIONS.createPointOfContact,
          user!.GUID,
        );
      });
  };

  return (
    <>
      {allowAddPointOfContact && (
        <ListItem
          aria-label="Point Of Contact Actions"
          onClick={handleClickOpen}
          disabled={!allowAddPointOfContact}
          style={{ marginTop: '10px', paddingLeft: '20px' }}
          startItem={
            <Icon icon={UserPlus} size="md" style={{ marginBottom: '8px' }} />
          }
          endItem={
            <Icon icon={Plus} size="md" style={{ marginRight: '10px' }} />
          }
        >
          <Typography
            variant="body1"
            color="secondary"
            fontWeight="bold"
            align="left"
            data-cy="create-point-of-contact"
            style={{
              paddingTop: '30px',
              paddingLeft: '27px',
              marginTop: '-35px',
            }}
          >
            Add Point of Contact
          </Typography>
          <Divider
            variant="fullLength"
            style={{ paddingTop: '10px', marginLeft: '14px', width: '95%' }}
          />
        </ListItem>
      )}
      <Modal
        data-cy="close-add-point-of-contact"
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
      >
        <>
          <ModalHeader> Add New Point Of Contact </ModalHeader>
          <ModalBody>
            Fill out the required fields to add a consultant to this
            subdepartment.
            <Container style={{ textAlign: 'left' }}>
              <Row style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                <Col sm={6}>
                  <UserDropdown
                    user={user}
                    setUser={selectedUser => {
                      setUser(selectedUser);
                      selectedUser &&
                        role &&
                        setErrorMessage(
                          isDuplicate(selectedUser, role)
                            ? 'Point of contact already assigned to this role for this subdepartment.'
                            : null,
                        );
                    }}
                    data-cy="select-point-of-contact-name"
                  />
                </Col>
                <Col sm={6}>
                  <Dropdown<Role>
                    label="Position"
                    options={rolesList}
                    value={role}
                    data-cy="select-point-of-contact-role"
                    onChange={selectedRole => {
                      setRole(selectedRole);
                      user &&
                        setErrorMessage(
                          isDuplicate(user, selectedRole)
                            ? 'Point of contact already assigned to this role for this subdepartment.'
                            : null,
                        );
                    }}
                    placeholder="Select a role"
                    getOptionId={option => option.id}
                    getOptionText={option => option.name}
                    renderOption={option => option.name}
                    fullWidth
                    required
                  />
                </Col>
              </Row>
              {errorMessage && (
                <Typography
                  variant="body2"
                  color="primary"
                  data-cy="duplicate-error"
                >
                  {errorMessage}
                </Typography>
              )}
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              size="lg"
              onClick={handleSaveClose}
              variant="filled"
              disabled={!user || !role || isDuplicate(user, role)}
              data-cy="save-point-of-contact"
            >
              Add Point of Contact
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

export default AddPointOfContactListItem;
