import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { CreateGuildButton } from 'app/components/shared/FormDialog/CreateGuild';
import GuildFAQ from 'app/components/shared/FormDialog/GuildFAQ';
import GuildCard from 'app/components/shared/GuildsCard/GuildsCard';
import { useAppDispatch, useAppSelector } from 'app/store';
import { BreadcrumbActions } from 'app/store/breadcrumbs/BreadcrumbClickMapper';
import { replaceBreadcrumbsAction } from 'app/store/breadcrumbs/actions';
import { selectIsNavigatingBack } from 'app/store/breadcrumbs/selectors';
import { selectGuilds } from 'app/store/guilds/selectors';
import { Guild } from 'app/types/Guild';
import { handleBackToSearchResults } from 'app/utils/breadcrumbSessionStorage';
import { allowEditGuild } from 'app/utils/hasPermissions/allowFeature';
import { List, ListItem, Typography } from 'cfa-react-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Guilds.scss';

export const Guilds = () => {
  const guildsList: Guild[] = useAppSelector(selectGuilds);
  const allowGuildCreate: boolean = allowEditGuild();

  const fromSearch = sessionStorage.getItem('fromSearch');
  const isNavigatingBack = useAppSelector(selectIsNavigatingBack);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // using session storage to see where user comes from
    if (fromSearch === 'true') {
      handleBackToSearchResults(dispatch, navigate);
    } else {
      if (isNavigatingBack) {
        console.log('NAVIGATING BACK');
        console.log(isNavigatingBack);
        return; // Exit if pod is not yet loaded or navigating back
      } else {
        const guildsBreadcrumb = {
          label: 'Guilds',
          action: BreadcrumbActions.Guilds,
        };
        dispatch(replaceBreadcrumbsAction([guildsBreadcrumb]));
      }
    }
  }, []);

  return (
    <>
      <Navigation />
      <div className="main-container">
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            Guilds
          </Typography>
        </div>
        <div>
          <List
            className="guilds-List"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(415px, 1fr))', // Adjust the column width as needed
              gridGap: '20px',
              marginLeft: '40px',
              marginRight: '40px',
            }}
          >
            {guildsList.map(guild => {
              return (
                <ListItem
                  key={guild.id}
                  style={{
                    width: '415px',
                    maxWidth: '415px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    alignContent: 'center',
                  }}
                >
                  <GuildCard guild={guild} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
      <Footer />
      <div className="create-guild-button">
        <CreateGuildButton allowGuildCreate={allowGuildCreate} />
        <GuildFAQ />
      </div>
    </>
  );
};

export default Guilds;
