import { NavigateFunction } from 'react-router-dom';
import { AppDispatch } from '..';
import { clearBreadcrumbAction, popBreadcrumbAction } from './actions';

export enum BreadcrumbActions {
  Pods,
  PodsProfile,
  Leadership,
  LeadershipProfile,
  Guilds,
  GuildProfile,
  Profile,
  Search,
}

// handler function type for pages that have groups of entities ie: pods, guilds
type GroupPageClickHandler = (
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  pathName: string,
) => void;

// handler function type for pages displaying a single entity ie: PodsProfile, LeadershipProfile
type ProfilePageClickHandler = (
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  pathName: string,
  entityId: string,
) => void;

type SearchClickHandler = (
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  pathName: string,
  searchQuery: string,
) => void;

type BreadcrumbMapper = {
  [BreadcrumbActions.Pods]: GroupPageClickHandler;
  [BreadcrumbActions.Leadership]: GroupPageClickHandler;
  [BreadcrumbActions.Guilds]: GroupPageClickHandler;
  [BreadcrumbActions.PodsProfile]: ProfilePageClickHandler;
  [BreadcrumbActions.LeadershipProfile]: ProfilePageClickHandler;
  [BreadcrumbActions.GuildProfile]: ProfilePageClickHandler;
  [BreadcrumbActions.Profile]: ProfilePageClickHandler;
  [BreadcrumbActions.Search]: SearchClickHandler;
};

export const BreadcrumbClickMapper: BreadcrumbMapper = {
  [BreadcrumbActions.Pods]: (
    dispatch: AppDispatch,
    navigate: NavigateFunction,
    pathName: string,
  ) => {
    if (pathName !== '/pods') {
      navigate('/pods');
      dispatch(clearBreadcrumbAction());
    }
  },
  [BreadcrumbActions.PodsProfile]: (
    dispatch: AppDispatch,
    navigate: NavigateFunction,
    pathName: string,
    entityId: string,
  ) => {
    if (pathName !== `/pods/${entityId}`) {
      navigate(`/pods/${entityId}`);
      dispatch(popBreadcrumbAction());
    }
  },
  [BreadcrumbActions.Leadership]: (
    dispatch: AppDispatch,
    navigate: NavigateFunction,
    pathName: string,
  ) => {
    if (pathName !== '/leadership-teams') {
      navigate('/leadership-teams');
      dispatch(clearBreadcrumbAction());
    }
  },
  [BreadcrumbActions.LeadershipProfile]: (
    dispatch: AppDispatch,
    navigate: NavigateFunction,
    pathName: string,
    entityId: string,
  ) => {
    if (pathName !== `/leadership-teams/${entityId}`) {
      navigate(`/leadership-teams/${entityId}`);
      dispatch(popBreadcrumbAction());
    }
  },
  [BreadcrumbActions.Guilds]: (
    dispatch: AppDispatch,
    navigate: NavigateFunction,
    pathName: string,
  ) => {
    if (pathName !== `/guilds`) {
      navigate('/guilds');
      dispatch(clearBreadcrumbAction());
    }
  },
  [BreadcrumbActions.GuildProfile]: (
    dispatch: AppDispatch,
    navigate: NavigateFunction,
    pathName: string,
    entityId: string,
  ) => {
    if (pathName !== `/guilds/${entityId}`) {
      navigate(`/guilds/${entityId}`);
      dispatch(popBreadcrumbAction());
    }
  },
  [BreadcrumbActions.Profile]: (
    dispatch: AppDispatch,
    navigate: NavigateFunction,
    pathName: string,
    entityId: string,
  ) => {
    if (pathName !== `/profile/${entityId}`) {
      navigate(`/profile/${entityId}`);
      dispatch(popBreadcrumbAction());
    }
  },
  [BreadcrumbActions.Search]: (
    dispatch: AppDispatch,
    navigate: NavigateFunction,
    pathName: string,
    searchQuery: string,
  ) => {
    if (pathName !== `/search?q=${searchQuery}`) {
      navigate(`/search?q=${searchQuery}`);
      dispatch(popBreadcrumbAction());
    }
  },
};
