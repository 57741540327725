import { ActionsVertical } from '@cfa-icons/system';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import {
  removePointOfContact,
  updatePointOfContact,
} from 'app/store/points_of_contact/actions';
import Department from 'app/types/Department';
import Member from 'app/types/Member';
import { Role } from 'app/types/Role';
import { allowAddPointOfContact } from 'app/utils/hasPermissions/allowFeature';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { DeleteObjectModal } from './DeleteObjectModal';

export type EditPointOfContactProps = {
  currentDepartment: Department;
  rolesList: Role[];
  point_of_contact: Member;
};

export const EditPointOfContact = ({
  currentDepartment,
  rolesList,
  point_of_contact,
}: EditPointOfContactProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState<Role | null>(null);
  const allowPointOfContactEdit: boolean = allowAddPointOfContact();

  useEffect(() => {
    let currentRole =
      rolesList.find(role => role.id === point_of_contact.roleId) || null;
    setRole(currentRole);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveClose = () => {
    dispatch(updatePointOfContact(point_of_contact, currentDepartment!, role!))
      .then(() => {
        setRole(null);
        setOpen(false);
      })
      .catch(e => {
        displayErrorToast(
          e.message,
          ACTIONS.editPointOfContact,
          point_of_contact.userId,
        );
      });
  };

  const handleDelete = () => {
    dispatch(removePointOfContact(point_of_contact, currentDepartment!))
      .then(() => {
        setRole(null);
        setOpen(false);
      })
      .catch(e => {
        displayErrorToast(
          e.message,
          ACTIONS.deletePointOfContact,
          point_of_contact.userId,
        );
      });
  };

  return (
    <>
      <IconButton
        aria-label="Point of Contact Actions"
        size="md"
        onClick={handleClickOpen}
      >
        <Icon icon={ActionsVertical} />
      </IconButton>
      <Modal
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
        disableOverlayClick={true}
      >
        <>
          <ModalHeader>Edit Info for {point_of_contact.name} </ModalHeader>
          <ModalBody>
            Edit the fields below or delete the point of contact.
            <Container style={{ textAlign: 'left' }}>
              <>
                <Row style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                  <Col sm={6}>
                    <Dropdown<string>
                      label="Point of Contact"
                      value={point_of_contact.name}
                      options={[]}
                      onChange={function noRefCheck() {}}
                      fullWidth
                      readOnly
                    />
                  </Col>
                  <Col sm={6}>
                    <Dropdown<Role>
                      label="Position"
                      options={rolesList}
                      value={role}
                      onChange={e => setRole(e)}
                      placeholder="Select a role"
                      getOptionId={option => option.id}
                      getOptionText={option => option.name}
                      renderOption={option => option.name}
                      fullWidth
                      required
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: '1rem', marginTop: '1.5rem' }} />
              </>
            </Container>
          </ModalBody>
          <ModalFooter>
            <DeleteObjectModal
              type="Point of Contact"
              itemName={point_of_contact.name}
              handleClose={handleClose}
              handleDelete={handleDelete}
              allowDelete={allowPointOfContactEdit}
            />
            <Button
              size="lg"
              onClick={handleSaveClose}
              variant="filled"
              disabled={!role}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

export default EditPointOfContact;
