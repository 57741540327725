import { Member } from 'app/types/Member';
import Role from 'app/types/Role';
import { sortMembersWithRoles } from 'app/utils/sortMembersWithRoles';
import { Typography } from 'cfa-react-components';
import React, { useEffect, useState } from 'react';
import { MemberRoles } from './MemberRoles';

type GroupProps = {
  members?: Member[];
};

type RoleSelectionProps<T> = {
  members: Member[];
  rolesList: Role[];
  group: T;
  staffOnly?: boolean;
};

// The values as arguments come from the PodCard.js file.
export const RoleGroups = <T extends GroupProps>({
  rolesList,
  staffOnly,
  group,
  members,
}: RoleSelectionProps<T>) => {
  const [memberRolesMap, setRolesMap] = useState(new Map<Role, Member[]>());

  useEffect(() => {
    if (members.length > 0) {
      const { map: sortedMap } = sortMembersWithRoles(
        members,
        rolesList,
        staffOnly!,
      );
      setRolesMap(sortedMap);
    }
  }, [members, staffOnly]);

  if (group.members?.length === 0 || undefined) {
    return (
      <>
        <Typography variant="body1" fontWeight="bold">
          Members
        </Typography>
        <Typography variant="body2"> No members in this pod. </Typography>
      </>
    );
  }
  return (
    <>
      {[...memberRolesMap].map(([role, members]) => {
        const roleName =
          (members ?? []).length > 1 &&
          role.name.charAt(role.name.length - 1) !== 's'
            ? `${role.name}s`
            : role.name;

        return (
          <React.Fragment key={role.name}>
            {members ? (
              <>
                <MemberRoles
                  key={role.name}
                  roleName={roleName}
                  members={members}
                />
              </>
            ) : (
              <>
                <Typography variant="h2" color="primary" align="center">
                  An unexpected error occurred.
                </Typography>
              </>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
export default RoleGroups;
