enum MemberType {
  PODS = 'PODS',
  LEADERSHIP = 'LEADERSHIP',
  STEERING = 'STEERING',
  GUILD = 'GUILD',
  POINT_OF_CONTACT = 'CONSULTANT',
  CONTRACTOR = 'CONTRACTOR',
}

export default MemberType;
