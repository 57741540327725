import { ChevronLeft } from '@cfa-icons/system';
import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import MemberInfoSection from 'app/components/shared/CardRoleGroups/MemberInfoSection';
import { PictureDownloadButton } from 'app/components/shared/PictureDownloadButton/PictureDownloadButton';
import { useAppDispatch, useAppSelector } from 'app/store';
// import { setBreadcrumbAction } from 'app/store/breadcrumbs/actions';
import { CardTag } from 'app/components/shared/CardTag/CardTag';
import { BreadcrumbActions } from 'app/store/breadcrumbs/BreadcrumbClickMapper';
import {
  popBreadcrumbAction,
  pushBreadcrumbAction,
  setIsNavigatingBackAction,
} from 'app/store/breadcrumbs/actions';
import {
  breadcrumbExists,
  buildBreadcrumbsFromStore,
} from 'app/store/breadcrumbs/breadcrumbHelper';
import { selectIsNavigatingBack } from 'app/store/breadcrumbs/selectors';
import { selectDepartmentById } from 'app/store/dept/selectors';
import { selectLeadershipById } from 'app/store/leadership/selectors';
import { EmployeeImageData } from 'app/types/ImageTypes';
import { allowEditLT } from 'app/utils/hasPermissions/allowFeature';
import { useLeadershipMembers } from 'app/utils/hooks/useMembers';
import {
  Breadcrumbs,
  Button,
  Divider,
  Icon,
  List,
  Tooltip,
  Typography,
} from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { handleBackToSearchResults } from '../../utils/breadcrumbSessionStorage';
import PageNotFound from '../PageNotFound/PageNotFound';
import './Leadership.scss';

export const LeadershipProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { leadershipId } = useParams() as { leadershipId: string };
  const [userImageData, setUserImageData] = useState<EmployeeImageData[]>([]);
  const leadership = useAppSelector(selectLeadershipById(leadershipId));
  const breadcrumbs = buildBreadcrumbsFromStore(
    dispatch,
    navigate,
    location.pathname,
  );
  const allowLeadershipEdit: boolean = allowEditLT();
  const isNavigatingBack = useAppSelector(selectIsNavigatingBack);
  const leadershipMembers = useLeadershipMembers(leadershipId, false);

  const EditLeadership = () => {
    navigate(`/leadership-teams/${leadership?.id}/edit`);
  };

  const currentDept = useAppSelector(selectDepartmentById(leadership?.deptId!));
  const fromSearch = sessionStorage.getItem('fromSearch');

  const sortedTags = leadership?.tags
    ? [...leadership.tags].sort((a, b) => {
        const tagA = a.toLowerCase();
        const tagB = b.toLowerCase();
        return tagA.localeCompare(tagB);
      })
    : [];

  useEffect(() => {
    // using session storage to see where user comes from
    if (fromSearch === 'true') {
      handleBackToSearchResults(dispatch, navigate);
    } else {
      if (!leadership || isNavigatingBack) {
        console.log('NAVIGATING BACK');
        console.log(isNavigatingBack);
        return; // Exit if pod is not yet loaded or navigating back
      } else {
        const leadershipBreadcrumb = {
          label: leadership?.name || 'Leadership Profile',
          action: BreadcrumbActions.LeadershipProfile,
          entityId: leadershipId,
        };

        if (!breadcrumbExists(leadershipBreadcrumb, breadcrumbs)) {
          dispatch(pushBreadcrumbAction(leadershipBreadcrumb));
        }
      }
    }
  }, []);

  const handleBackClick = () => {
    console.log('HANDLE BACK CLICKED CALLED');
    dispatch(setIsNavigatingBackAction(true)); // Set the flag
    console.log('Dispatch called!');
    dispatch(popBreadcrumbAction());
    window.history.back(); // Go back to the previous page

    // Clear the flag after a short delay to allow the component to re-render
    setTimeout(() => {
      dispatch(setIsNavigatingBackAction(false));
    }, 0);
  };

  return leadership ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <div className="navigation-breadcrumbs-wrapper">
            {fromSearch && (
              <>
                <Icon icon={ChevronLeft} className="info" size="sm" />
              </>
            )}
            {breadcrumbs.length > 3 ? (
              <>
                <Icon icon={ChevronLeft} className="info" size="sm" />
                <Breadcrumbs
                  breadcrumbs={[
                    {
                      label: 'Back',
                      onClick: handleBackClick,
                    },
                  ]}
                />
              </>
            ) : (
              <Breadcrumbs
                data-cy="leadership-profile-breadcrumb"
                maxItems={4}
                breadcrumbs={breadcrumbs}
              />
            )}
          </div>
          {allowLeadershipEdit ? (
            <Button
              color="primary"
              onClick={EditLeadership}
              size="md"
              data-cy="edit-lt"
              variant="outlined"
              style={{
                justifySelf: 'end',
              }}
              disabled={!allowLeadershipEdit}
            >
              Edit Leadership Team
            </Button>
          ) : (
            <Tooltip content="Insufficient Permissions">
              <span>
                <Button
                  color="primary"
                  onClick={EditLeadership}
                  data-cy="edit-lt"
                  size="md"
                  variant="outlined"
                  style={{
                    justifySelf: 'end',
                  }}
                  disabled={!allowLeadershipEdit}
                >
                  Edit Leadership Team
                </Button>
              </span>
            </Tooltip>
          )}
        </div>
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            {leadership.name}
          </Typography>
        </div>
        <div className="dept-section">
          <Typography
            variant="h3"
            color="secondary"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            <div style={{ color: '#80878C', fontSize: '22px' }}>
              {currentDept?.name!}
            </div>
          </Typography>
        </div>
        <div className="lt-profile-tags-section">
          <div
            data-cy="lt-profile-tags"
            style={{
              display: 'flex',
              justifyContent: 'center',
              maxWidth: '600px',
              flexWrap: 'wrap',
              alignContent: 'center',
            }}
          >
            {sortedTags?.map((tag, index) => <CardTag key={index} tag={tag} />)}
          </div>
        </div>
        <div className="dept-info">
          <Typography
            variant="body1"
            color="secondary"
            fontWeight="medium"
            align="center"
            gutterBottom
            data-cy="pod-info"
          >
            {leadership.info}
          </Typography>
        </div>
        <div
          style={{
            width: '800px',
            margin: '0 auto',
            marginTop: '20px',
          }}
        >
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <List
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gridTemplateColumns: 'repeat(4, 1fr)',
              justifyContent: 'center',
              alignItems: 'stretch',
            }}
          >
            {leadershipMembers?.map((member, index) => (
              <MemberInfoSection
                member={member}
                index={index}
                setUserImageData={setUserImageData}
              />
            ))}
          </List>
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <PictureDownloadButton
            userImageData={userImageData}
            staffOnly={false}
            name={leadership.name}
          />
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <PageNotFound></PageNotFound>
  );
};
