import { PropsWithChildren, useContext } from 'react';
import { MembersContext } from './MembersContext';

export const SaveCloseButtonWrapper = ({ children }: PropsWithChildren<{}>) => {
  const { inputRowList, setInputRowList } = useContext(MembersContext);
  return (
    <div
      className="saveAndCloseButtons"
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {children}
      {/*  */}
    </div>
  );
};
