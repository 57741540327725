import { useAppSelector } from 'app/store';
import { selectUsers } from 'app/store/users/selectors';
import Company from 'app/types/Company';
import { titleCase } from 'app/utils/namingFormat';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LineBreak,
  Results,
  SearchHeader,
  SearchSectionProps,
} from './SearchPage';

export const CompanyNameSearchSection = ({ search }: SearchSectionProps) => {
  const navigate = useNavigate();

  const clickCompany = (name: string) => {
    navigate(`/company/${encodeURIComponent(name)}`);
  };

  function extractCompanies(identities: any[]): Set<Company> {
    const companies: Set<Company> = new Set<Company>();
    const names: Set<string> = new Set<string>();

    for (const identity of identities) {
      if (identity && identity.company) {
        const companyName = titleCase(identity.company);
        names.add(companyName);
      }
    }
    names.forEach(c => {
      const myCompany: Company = { name: c };
      companies.add(myCompany);
    });
    return companies;
  }

  const identities = useAppSelector(selectUsers);
  const [companyCollapse, setCompanyCollapse] = useState(true);
  const [companyResults, setCompanyResults] = useState<Company[]>(
    [] as Company[],
  );

  useEffect(() => {
    const companies = extractCompanies(identities);
    const searchedCompanies = Array.from(companies).filter(company =>
      company.name.toLowerCase().includes(search.toLowerCase()),
    );
    setCompanyResults(searchedCompanies);
  }, [search, identities]);

  return (
    <>
      <SearchHeader
        title="External Worker Companies"
        size={companyResults.length}
        collapse={companyCollapse}
        setCollapse={setCompanyCollapse}
      />
      <LineBreak />
      <Results<Company>
        results={companyResults}
        collapse={companyCollapse}
        navigation={clickCompany}
      />
    </>
  );
};
