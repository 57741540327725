import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { DeleteObjectModal } from 'app/components/shared/FormDialog/DeleteObjectModal';
import { EditTags } from 'app/components/shared/FormDialog/EditGroupTags';
import { EditDescription } from 'app/components/shared/ProfilePages/EditGroupDescription';
import { EditMembers } from 'app/components/shared/ProfilePages/EditMembers';
import { EditTitle } from 'app/components/shared/ProfilePages/EditTitle';
import { MembersContext } from 'app/components/shared/ProfilePages/MembersContext';
import { SaveAndCloseButtons } from 'app/components/shared/ProfilePages/SaveAndCloseButtons';
import RoleType from 'app/constants/ApiRole';
import ApiState from 'app/constants/ApiState';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectSelectedDeptTab } from 'app/store/app/selectors';
import {
  selectDepartmentById,
  selectDepartments,
} from 'app/store/dept/selectors';
import {
  createPodMembers,
  reloadPod,
  removePod,
  removePodMembers,
  updatePod,
  updatePodMembers,
} from 'app/store/pods/actions';
import { selectPodById } from 'app/store/pods/selectors';
import { usePodRoles } from 'app/store/roles/selectors';
import { selectLoadUserStatus, selectUsers } from 'app/store/users/selectors';
import Department from 'app/types/Department';
import { InputRowType } from 'app/types/InputRowType';
import Pod from 'app/types/Pod';
import { compareArrays } from 'app/utils/compareArrays';
import { allowEditPodMember } from 'app/utils/hasPermissions/allowFeature';
import { usePodMembers } from 'app/utils/hooks/useMembers';
import { shouldEnableSaveGroups } from 'app/utils/shouldEnableSaveGroups';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import { getMembersChangeSet } from 'app/utils/useGetMembersChangeSet';
import {
  Breadcrumbs,
  Col,
  Dropdown,
  LoadingIndicator,
} from 'cfa-react-components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';
import './PodStyling.scss';

export const PodsEditProfilePage = () => {
  const navigate = useNavigate();

  const { podId } = useParams() as { podId: string };
  let pod = useAppSelector(selectPodById(podId));

  const [infoText, setInfoText] = useState(pod?.info);
  const [podName, setPodName] = useState(pod?.name);
  const userStatus = useAppSelector(selectLoadUserStatus);

  const podMembers = usePodMembers(podId, false);
  const dispatch = useAppDispatch();
  const allowPodEdit: boolean = allowEditPodMember();
  const users = useAppSelector(selectUsers);
  const rolesList = usePodRoles();
  const [isDisabled, setIsDisabled] = useState(true);
  const deptList = useAppSelector(selectDepartments);
  const [selectedTags, setSelectedTags] = useState(
    pod?.tags !== undefined ? pod.tags : [],
  );
  const selectedDeptTab = useAppSelector(selectSelectedDeptTab);
  const currentDept = useAppSelector(selectDepartmentById(selectedDeptTab));
  const [subDept, setSubDept] = React.useState<Department | null>(
    currentDept || null,
  );
  const [loading, setLoading] = useState(false);

  const [inputRowList, setInputRowList] = useState<InputRowType[]>(
    [] as InputRowType[],
  );

  const providerValue = { inputRowList, setInputRowList };

  useEffect(() => {
    if (currentDept !== undefined) {
      setSubDept(currentDept);
    }
  }, [currentDept]);

  const shouldEnableSaveButton = () => {
    const groupFunc = () => {
      if (podName !== pod?.name) {
        return true;
      }

      if (infoText !== pod?.info) {
        return true;
      }

      if (subDept?.id !== pod?.deptId) {
        return true;
      }

      if (pod?.tags !== undefined && compareArrays(selectedTags, pod?.tags)) {
        return true;
      }
      return false;
    };
    return shouldEnableSaveGroups(inputRowList, podMembers!, groupFunc);
  };

  useEffect(() => {
    setIsDisabled(!shouldEnableSaveButton());
  }, [inputRowList, podName, infoText, subDept, selectedTags]);

  const handleClosePod = () => {
    if (pod) {
      navigate(`/pods/${pod.id}`);
    }
  };

  const saveDeletePod = async () => {
    dispatch(removePod(podId))
      .then(() => {
        navigate('/pods');
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.deletePod, podId);
      });
  };

  const updateNameDescription = async () => {
    if (pod) {
      dispatch(
        updatePod(
          pod,
          podName ?? '',
          infoText ?? '',
          subDept?.id!,
          selectedTags,
        ),
      ).catch(error => {
        displayErrorToast(error.message, ACTIONS.editPod, pod?.id);
      });
    }
  };

  const updatePeople = async () => {
    if (!pod) {
      return;
    }
    const result = getMembersChangeSet(
      pod,
      podName!,
      infoText!,
      rolesList,
      podMembers!,
      inputRowList,
      RoleType.POD_ROLE,
      users,
    );
    if (result === undefined) {
      return;
    }
    const {
      tempGroup,
      deletedMemberIds,
      newMembers,
      addedRoles,
      changedMembers,
      updatedRoles,
    } = result;
    await Promise.all([
      dispatch(removePodMembers(tempGroup, deletedMemberIds)).catch(error => {
        displayErrorToast(error.message, ACTIONS.deleteMember, tempGroup.id);
      }),
      dispatch(createPodMembers(newMembers, tempGroup, addedRoles)).catch(
        error => {
          displayErrorToast(error.message, ACTIONS.createMember, tempGroup.id);
        },
      ),
      dispatch(updatePodMembers(tempGroup, changedMembers, updatedRoles)).catch(
        error => {
          displayErrorToast(error.message, ACTIONS.editMember, tempGroup.id);
        },
      ),
    ]).catch(error => {
      console.log(error.message);
    });

    await dispatch(reloadPod(pod!.id));
    {
      dispatch(
        updatePod(
          pod,
          podName ?? '',
          infoText ?? '',
          subDept?.id!,
          selectedTags,
        ),
      ).catch(error => {
        displayErrorToast(error.message, ACTIONS.editPod, pod?.id);
      });
    }
  };

  const saveUpdatePod = async () => {
    setLoading(true);
    updateNameDescription();
    if (!pod) {
      pod = { id: '' } as Pod;
    }
    await updatePeople();
    navigate(`/pods/${podId}`);
    setLoading(false);
  };

  if (userStatus.state == ApiState.LOADING || loading) {
    return (
      <div className="loading">
        <LoadingIndicator variant={'page'}></LoadingIndicator>
      </div>
    );
  }

  return pod ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <Breadcrumbs
            breadcrumbs={[
              {
                label: 'Pods',
                onClick: function backToPods() {
                  navigate('/pods');
                },
              },
              {
                label: pod?.name,
              },
            ]}
          />
        </div>
        <EditTitle titleText={podName} setTitleText={setPodName} />
        <div
          style={{
            marginTop: '-1.5rem',
            marginBottom: '20px',
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Dropdown
            onChange={e => {
              setSubDept(e);
            }}
            style={{
              maxWidth: 'unset',
              width: '300px',
            }}
            options={deptList}
            getOptionId={option => option.id}
            getOptionText={option => option.name}
            renderOption={option => option.name}
            placeholder="Select a subdepartment"
            value={subDept}
          />
        </div>
        <EditDescription infoText={infoText} setInfoText={setInfoText} />
        <MembersContext.Provider value={providerValue}>
          <EditMembers members={podMembers} rolesList={rolesList}>
            <Col sm={12} style={{ marginTop: '.5rem' }}>
              <EditTags
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
              />
            </Col>
          </EditMembers>
        </MembersContext.Provider>
        <SaveAndCloseButtons
          saveUpdateGroup={saveUpdatePod}
          allowEditGroup={isDisabled}
          handleCloseGroup={handleClosePod}
          isDisabled={isDisabled}
        />
        <DeleteObjectModal
          type="Pod"
          itemName={pod.name}
          handleDelete={saveDeletePod}
          handleClose={function noRefCheck() {}}
          allowDelete={allowPodEdit}
        />
      </div>
      <Footer></Footer>
    </>
  ) : (
    <PageNotFound></PageNotFound>
  );
};
