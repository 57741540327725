import { IconPlus } from '@tabler/icons-react';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import { createTag } from 'app/store/tags/actions';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Col,
  Container,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  TextField,
} from 'cfa-react-components';
import React, { useEffect, useState } from 'react';
import './CreateTag.scss';

interface CreateTagButtonProps {
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTags: string[];
}

export const CreateTagButton: React.FC<CreateTagButtonProps> = ({
  setSelectedTags,
  selectedTags,
}) => {
  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const dispatch = useAppDispatch();

  const validateInputs = () => {
    return tag === '';
  };

  const handleClose = () => {
    setTag('');
    setOpen(false);
  };

  const handleSaveClose = async () => {
    setIsDisabled(true);
    dispatch(createTag(tag))
      .then(() => {
        setSelectedTags([...selectedTags, tag]);
        handleClose();
      })
      .catch(error => {
        setTag('');
        setIsDisabled(validateInputs);
        displayErrorToast(error.message, ACTIONS.createTag, tag);
      });
  };

  useEffect(() => {
    setIsDisabled(validateInputs());
  });

  return (
    <>
      <div className="addTagButton">
        <div
          className="create-tag-button"
          onClick={e => setOpen(true)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: '80px',
            marginTop: '10px',
          }}
          data-cy="add-member-btn"
        >
          <Icon
            icon={IconPlus}
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              justifyContent: 'center',
            }}
            height="1.5em"
            width="1.5em"
          />
          <h4
            data-cy="add-member"
            style={{
              marginLeft: '.75rem',
              marginTop: '.15rem',
              cursor: 'pointer',
            }}
          >
            {' '}
            Create New Tag
          </h4>
        </div>
      </div>
      <Modal
        show={open}
        onClose={handleClose}
        size="md"
        className="cfa-modal"
        disableOverlayClick={true}
      >
        <React.Fragment key=".1">
          <ModalHeader>Create New Tag</ModalHeader>
          <ModalBody
            style={{
              padding: '20px',
              overflowX: 'hidden',
            }}
          >
            <Container
              style={{
                textAlign: 'left',
              }}
            >
              <Col sm={12}>
                <TextField
                  label="Tag"
                  required
                  onChange={e => setTag(e.target.value)}
                  placeholder="Tag"
                  fullWidth
                  value={tag}
                  data-cy="tag-name-field"
                  maxLength={20}
                />
              </Col>
            </Container>
            <div
              style={{
                marginTop: '60px',
              }}
            >
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button
                style={{ marginLeft: '2rem', marginRight: '-2rem' }}
                onClick={handleSaveClose}
                variant="filled"
                disabled={isDisabled}
                data-cy="create-tag"
              >
                Create Tag
              </Button>
            </div>
          </ModalBody>
        </React.Fragment>
      </Modal>
    </>
  );
};
export default CreateTagButton;
