import { IconPlus } from '@tabler/icons-react';
import { EditTags } from 'app/components/shared/FormDialog/EditGroupTags';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectDepartments } from 'app/store/dept/selectors';
import { createLeadership } from 'app/store/leadership/actions';
import { useLeadershipRoles } from 'app/store/roles/selectors';
import Department from 'app/types/Department';

import { IdentityUser } from 'app/types/IdentityUser';
import { InputRowType } from 'app/types/InputRowType';
import Member from 'app/types/Member';
import MemberStatus from 'app/types/MemberStatus';
import MemberType from 'app/types/MemberType';
import { Role } from 'app/types/Role';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  TextField,
  Tooltip,
} from 'cfa-react-components';
import React, { useEffect, useRef, useState } from 'react';
import '../../../../App.scss';
import InputRow from '../InputRow';

type CreateProps = {
  allowCreate: boolean;
};

export const CreateLTButton = ({ allowCreate }: CreateProps) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [inputRowList, setInputRowList] = useState<InputRowType[]>([
    { index: 0, role: null, user: null, deleted: false },
  ]);
  const deptList = useAppSelector(selectDepartments);
  const currentDept = null;
  const [subDept, setSubDept] = React.useState<Department | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const roleList = useLeadershipRoles();

  const dispatch = useAppDispatch();

  const handleAddInputRow = () => {
    setInputRowList([
      ...inputRowList,
      { index: inputRowList.length, role: null, user: null, deleted: false },
    ]);
  };

  useEffect(() => {
    if (currentDept !== undefined && currentDept != null) {
      setSubDept(currentDept);
    }
  }, [currentDept]);

  useEffect(() => {
    setIsDisabled(validateInputs());
  }, [inputRowList, name]);

  const handleChangeInputRow = (
    index: number,
    tempRole: Role | null,
    tempUser: IdentityUser | null,
    from: string,
  ) => {
    const tempInputRows = [...inputRowList];
    if (from === 'USER') {
      tempInputRows[index] = {
        index: tempInputRows[index].index,
        role: tempInputRows[index].role,
        user: tempUser,
        deleted: false,
      };
    }
    if (from === 'ROLE') {
      tempInputRows[index] = {
        index: tempInputRows[index].index,
        role: tempRole,
        user: tempInputRows[index].user,
        deleted: false,
      };
    }
    setInputRowList(tempInputRows);
  };

  const handleDeleteInputRow = (indexToDelete: number) => {
    const tempInputRows = [...inputRowList];
    tempInputRows[indexToDelete] = {
      index: tempInputRows[indexToDelete].index,
      role: tempInputRows[indexToDelete].role,
      user: tempInputRows[indexToDelete].user,
      deleted: true,
    };
    setInputRowList(tempInputRows);
  };

  const validateInputs = () => {
    return inputRowList
      .filter(item => {
        return item.deleted == false;
      })
      .some(item => {
        return item.user == null || name == '';
      });
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [inputRowList.length]);

  const handleClose = () => {
    setSelectedTags([]);
    setName('');
    setOpen(false);
    setDescription('');
    resetInputList();
    setSubDept(null);
  };
  const resetInputList = () => {
    setInputRowList([{ index: 0, role: null, user: null, deleted: false }]);
  };

  const handleSaveClose = async () => {
    setIsDisabled(true);
    const leadershipMemberList: Member[] = [];
    inputRowList.forEach(item => {
      if (item.deleted == false) {
        const member: Member = {
          userId: item?.user?.GUID ?? '',
          groupId: 'temp',
          type: MemberType.LEADERSHIP,
          name: item.user?.displayName ?? '',
          isContingent: item.user?.AUDIENCE_TAG === 'CONTRACTOR_AUDIENCE',
          status: MemberStatus.ACTIVE,
          roleId: item.role?.id,
        };
        leadershipMemberList.push(member);
      }
    });

    dispatch(
      createLeadership(
        name,
        description,
        leadershipMemberList,
        subDept?.id,
        selectedTags,
      ),
    )
      .then(() => {
        handleClose();
      })
      .catch(error => {
        setIsDisabled(validateInputs());
        displayErrorToast(error.message, ACTIONS.createLeadership);
      });
  };

  return (
    <>
      {allowCreate ? (
        <Button
          size="sm"
          onClick={e => {
            setOpen(true);
            setSubDept(null);
          }}
          style={{
            height: '40px',
          }}
          disabled={!allowCreate}
          data-cy="create-lt"
        >
          Create Leadership Team
        </Button>
      ) : (
        <Tooltip content="Insufficient Permissions">
          <span>
            <Button
              data-cy="create-lt"
              size="sm"
              onClick={e => {
                setOpen(true);
                setSubDept(null);
              }}
              style={{
                height: '40px',
              }}
              disabled={!allowCreate}
            >
              Create Leadership Team
            </Button>
          </span>
        </Tooltip>
      )}
      <Modal
        show={open}
        onClose={handleClose}
        size="lg"
        scrollMode="modal-body"
        disableOverlayClick={true}
      >
        <React.Fragment key=".0">
          <ModalHeader>Create New Leadership Team</ModalHeader>
          <ModalBody
            style={{
              paddingRight: '3px',
              overflowX: 'hidden',
            }}
          >
            To create a new leadership team, enter a team name and add team
            members.
            <Container
              ref={containerRef}
              style={{
                textAlign: 'left',
                maxHeight: '50vh',
                maxWidth: '',
                overflowY: 'scroll',
              }}
            >
              <Row
                style={{
                  marginRight: '30px',
                  marginBottom: '1rem',
                  marginTop: '1rem',
                }}
              >
                <Col sm={12}>
                  <TextField
                    label="Leadership Team Name"
                    required
                    onChange={e => setName(e.target.value)}
                    placeholder="Leadership Team Name"
                    data-cy="lt-name-field"
                    fullWidth
                    value={name}
                  />
                </Col>
                <Col
                  sm={16}
                  style={{
                    marginTop: '.5rem',
                    display: 'flex',
                  }}
                >
                  <Col
                    sm={9}
                    style={{
                      marginLeft: '-1rem',
                      paddingLeft: '30px',
                      paddingRight: '10px',
                      width: '260px',
                    }}
                  >
                    <Dropdown
                      label="Subdepartment"
                      onChange={e => {
                        setSubDept(e);
                      }}
                      options={deptList}
                      getOptionId={option => option.id}
                      getOptionText={option => option.name}
                      renderOption={option => option.name}
                      placeholder="Select a Sub-department" // Placeholder now controls default text.
                      value={subDept} // Value will update when selection is made.
                      fullWidth
                      data-cy="lt-subdepartment-field"
                    />
                  </Col>
                  <Col
                    sm={9}
                    style={{
                      marginRight: '-1rem',
                      width: '297px',
                    }}
                  >
                    <EditTags
                      selectedTags={selectedTags}
                      setSelectedTags={setSelectedTags}
                    />
                  </Col>
                </Col>
                <Col sm={12} style={{ marginTop: '.5rem' }}>
                  <TextField
                    label="Leadership Team Description"
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Enter a description for the Leadership Team here"
                    multiline
                    rows={3}
                    fullWidth
                    value={description}
                  />
                </Col>
              </Row>
              <Col sm={12}>
                <Row
                  style={{
                    marginBottom: '1rem',
                    marginTop: '1.65rem',
                  }}
                >
                  <div style={{ fontWeight: 'bold', marginLeft: '-0.2rem' }}>
                    Add Leadership Team Members
                  </div>
                  <div style={{ marginRight: '20px' }}>
                    {inputRowList.map(item => {
                      if (item.deleted != true) {
                        return (
                          <InputRow
                            rolesList={roleList}
                            key={item.index}
                            onDelete={handleDeleteInputRow}
                            index={item.index}
                            handleInputChange={handleChangeInputRow}
                          />
                        );
                      }
                      return <></>;
                    })}
                  </div>
                </Row>
              </Col>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Container style={{ textAlign: 'left' }}>
              <Row
                style={{
                  marginBottom: '1rem',
                  marginTop: '1rem',
                }}
              >
                <Col
                  sm={12}
                  onClick={handleAddInputRow}
                  style={{
                    color: '#004F71',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '-.5rem',
                    marginTop: '-3rem',
                  }}
                >
                  <Icon
                    icon={IconPlus}
                    style={{
                      marginLeft: '-0.2rem',
                      textAlign: 'center',
                      cursor: 'pointer',
                      justifyContent: 'center',
                    }}
                    height="1.5em"
                    width="1.5em"
                  />
                  <h4
                    style={{
                      color: '#004F71',
                      marginLeft: '.75rem',
                      marginTop: '.15rem',
                      cursor: 'pointer',
                    }}
                  >
                    {' '}
                    Add Leadership Team Member
                  </h4>
                </Col>
                <Col
                  sm={12}
                  style={{
                    marginTop: '1.25rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Button onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: '2rem', marginRight: '-1.6rem' }}
                    onClick={handleSaveClose}
                    variant="filled"
                    disabled={isDisabled}
                    data-cy="create-lt-2"
                  >
                    Create Leadership Team
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    </>
  );
};

export default CreateLTButton;
