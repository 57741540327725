import { RootState, useAppSelector } from '..';

export const selectBreadcrumbs = (state: RootState) =>
  state.breadcrumbs.breadcrumbs;

export const selectIsNavigatingBack = (state: RootState) =>
  state.breadcrumbs.isNavigatingBack;

export const useBreadcrumbs = () => {
  const breadcrumbs = useAppSelector(selectBreadcrumbs);
  return { breadcrumbs };
};
