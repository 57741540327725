import { Check } from '@cfa-icons/system';
import RoleType from 'app/constants/ApiRole';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import { createRole } from 'app/store/roles/actions';
import { displayErrorToast } from 'app/utils/toasts/displayToast';

import { useLeadershipRoles } from 'app/store/roles/selectors';
import {
  Button,
  Container,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
} from 'cfa-react-components';
import * as React from 'react';

export const CreateLeaderRole = () => {
  const dispatch = useAppDispatch();
  const [role, setRole] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [leaderRoleError, setLeaderRoleError] = React.useState(false);
  const [rank, setRank] = React.useState<string | undefined>(undefined);
  const [showErrors, setShowErrors] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const ltRoles = useLeadershipRoles();
  // When the user clicks on the "Create New" button
  const handleClickOpen = () => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    setOpen(true);
  };
  // The input field for the new role name is cleared when the user closes handle without creating a new role
  const handleClose = () => {
    setLeaderRoleError(false);
    setOpen(false);
    setRole('');
    setRank(undefined);
    setShowErrors(false);
    setErrorText('');
  };

  const isInvalidRank = (str1: string): boolean => {
    const num1 = parseInt(str1, 10);
    if (isNaN(num1)) {
      return true;
    }

    return num1 < 0 || num1 > 100;
  };

  const handleSaveClose = async () => {
    setShowErrors(true);
    if (rank === '' || rank === undefined) {
      setErrorText('Field required');
      return;
    }
    if (isInvalidRank(rank)) {
      setErrorText('Rank must be between 0 and 100.');
      return;
    }
    try {
      if (ltRoles) {
        const roleExists = ltRoles.some(r => r.name === role);
        if (roleExists) {
          setLeaderRoleError(true);
          return;
        }
      }
      dispatch(createRole(role, RoleType.LT_ROLE, rank))
        .then(() => {
          setOpen(false);
          setRole(role);
          setErrorText('');
          setRank(undefined);
          setShowErrors(false);
        })
        .catch(error => {
          displayErrorToast(error.message, ACTIONS.createRole);
        });
    } catch {}
  };
  return (
    <>
      <Button
        className="create_btn"
        size="md"
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        style={{
          marginLeft: '20px',
          marginBottom: '10px',
          fontSize: '17px',
        }}
      >
        <React.Fragment key=".0">
          <Icon icon={Check} data-cy="create-lt-role-btn" />
          Create New
        </React.Fragment>
      </Button>

      <Modal
        data-cy="cancel-leadership"
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
        disableOverlayClick={true}
      >
        <React.Fragment key=".0">
          <ModalHeader>Create New Leadership Team Role</ModalHeader>
          <ModalBody>
            To create a new leadership team role, enter a new leadership role
            name.
            <Container style={{ textAlign: 'left', padding: 20 }}>
              <React.Fragment key=".0">
                <TextField
                  label="Leadership Team Role"
                  required
                  errorText={
                    leaderRoleError
                      ? 'Leadership role name already exists.'
                      : ''
                  }
                  onChange={e => {
                    setRole(e.target.value);
                    setLeaderRoleError(false);
                  }}
                  defaultValue=""
                  placeholder="Role Name"
                  fullWidth
                  data-cy="lt-role-in"
                />
              </React.Fragment>
              <React.Fragment key=".1">
                <TextField
                  errorText={showErrors ? errorText : ''}
                  label="Rank"
                  required
                  onChange={e => setRank(e.target.value)}
                  value={rank !== undefined ? rank.toString() : ''}
                  placeholder="Enter a rank value [0, 100]"
                  fullWidth
                  data-cy="lt-rank-in"
                />
              </React.Fragment>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleSaveClose}
              variant="filled"
              disabled={!role}
              data-cy="post-lt-role-btn"
            >
              Create Leadership Role
            </Button>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    </>
  );
};
export default CreateLeaderRole;
