import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
  Tooltip,
  Typography,
} from 'cfa-react-components';
import { useId, useState } from 'react';
import './DeleteObjectModal.scss';

export type DeleteObjectModalProps = {
  itemName: string;
  allowDelete?: boolean;
  deleteTooltipMessage?: string;
  handleDelete: () => void;
  handleClose: () => void;
  type: string;
};

export const DeleteObjectModal = ({
  itemName,
  allowDelete,
  deleteTooltipMessage,
  handleDelete,
  handleClose,
  type,
}: DeleteObjectModalProps) => {
  const [open, setOpen] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const id = useId();

  const handleDeleteOpen = (e: boolean) => {
    setOpen(e);
    if (e) {
      setDeleteText('');
    }
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const getStyle = () => {
    if (type === 'Guild' || type === 'Leadership Team' || type === 'Pod') {
      return 'filled';
    }
    return 'outlined';
  };

  const style = getStyle();

  return (
    <>
      <Tooltip
        content={deleteTooltipMessage}
        style={{
          display: !allowDelete && type == 'Sub-department' ? 'block' : 'none',
        }}
      >
        <span>
          <Button
            className={
              type === 'Guild' || type === 'Leadership Team' || type === 'Pod'
                ? 'corner-delete'
                : 'modal-delete'
            }
            onClick={event => {
              event.stopPropagation();
              handleDeleteOpen(true);
            }}
            variant={style}
            size="lg"
            disabled={!allowDelete}
            data-cy={'delete-button'}
          >
            Delete {type}
          </Button>
        </span>
      </Tooltip>
      <Modal
        show={open}
        onClose={() => {
          handleDeleteOpen(false);
          handleCloseModal();
        }}
        id={id}
        scrollMode="modal-body"
        disableOverlayClick={true}
      >
        <ModalHeader>Delete "{itemName}"</ModalHeader>
        <ModalBody>
          <Typography>
            Are you sure you want to delete "{itemName}" {type}?
          </Typography>

          <TextField
            data-cy="delete-text-field"
            placeholder="delete permanently"
            textAlign="start"
            helperText='To confirm, please type "delete permanently" in the box below '
            onChange={event => setDeleteText(event.target.value)}
            fullWidth
            margin
            cols={0}
            rows={0}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              handleDelete();
              setOpen(false);
            }}
            data-cy="delete-pod-final"
            variant="outlined"
            disabled={deleteText !== 'delete permanently'}
          >
            Delete {type}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
