import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { DeleteObjectModal } from 'app/components/shared/FormDialog/DeleteObjectModal';
import { EditTags } from 'app/components/shared/FormDialog/EditGroupTags';
import { EditGroupDepartment } from 'app/components/shared/ProfilePages/EditGroupDepartment';
import { EditDescription } from 'app/components/shared/ProfilePages/EditGroupDescription';
import { EditMembers } from 'app/components/shared/ProfilePages/EditMembers';
import { EditTitle } from 'app/components/shared/ProfilePages/EditTitle';
import { MembersContext } from 'app/components/shared/ProfilePages/MembersContext';
import { SaveAndCloseButtons } from 'app/components/shared/ProfilePages/SaveAndCloseButtons';
import RoleType from 'app/constants/ApiRole';
import ApiState from 'app/constants/ApiState';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  selectDepartmentById,
  selectDepartments,
} from 'app/store/dept/selectors';
import {
  createLeadershipMembers,
  reloadTeam,
  removeLeadershipMembers,
  removeLeadershipTeam,
  updateLeadershipMembers,
  updateLeadershipTeam,
} from 'app/store/leadership/actions';
import { selectLeadershipById } from 'app/store/leadership/selectors';
import { useLeadershipRoles } from 'app/store/roles/selectors';
import { selectLoadUserStatus, selectUsers } from 'app/store/users/selectors';
import Department from 'app/types/Department';
import { InputRowType } from 'app/types/InputRowType';
import { compareArrays } from 'app/utils/compareArrays';
import { allowEditLT } from 'app/utils/hasPermissions/allowFeature';
import { useLeadershipMembers } from 'app/utils/hooks/useMembers';
import { shouldEnableSaveGroups } from 'app/utils/shouldEnableSaveGroups';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import { getMembersChangeSet } from 'app/utils/useGetMembersChangeSet';
import { Breadcrumbs, Col, LoadingIndicator } from 'cfa-react-components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';
import './Leadership.scss';

export const LeadershipEditProfilePage = () => {
  const navigate = useNavigate();

  const { leadershipId } = useParams() as { leadershipId: string };
  const leadership = useAppSelector(selectLeadershipById(leadershipId));
  const deptList = useAppSelector(selectDepartments);
  const [selectedTags, setSelectedTags] = useState(
    leadership?.tags !== undefined ? leadership.tags : [],
  );
  const currentDept = useAppSelector(selectDepartmentById(leadership?.deptId!));

  const [infoText, setInfoText] = useState(leadership?.info);
  const [ltName, setLtName] = useState(leadership?.name);
  const [dept, setDept] = React.useState<Department>(currentDept!);
  const [isDisabled, setIsDisabled] = useState(true);
  const rolesList = useLeadershipRoles();
  const users = useAppSelector(selectUsers);
  const userStatus = useAppSelector(selectLoadUserStatus);

  const leadershipMembers = useLeadershipMembers(leadershipId, false);
  const dispatch = useAppDispatch();
  const allowLeadershipEdit: boolean = allowEditLT();

  const [inputRowList, setInputRowList] = useState<InputRowType[]>(
    [] as InputRowType[],
  );

  useEffect(() => {
    setIsDisabled(!shouldEnableSaveButton());
  }, [inputRowList, ltName, infoText, dept, selectedTags]);

  const shouldEnableSaveButton = () => {
    const groupCheckFunc = () => {
      if (ltName !== leadership?.name) {
        return true;
      }

      if (infoText !== leadership?.info) {
        return true;
      }

      if (dept != currentDept) {
        return true;
      }

      if (
        leadership?.tags !== undefined &&
        compareArrays(selectedTags, leadership?.tags)
      ) {
        return true;
      }

      return false;
    };
    return shouldEnableSaveGroups(
      inputRowList,
      leadershipMembers!,
      groupCheckFunc,
    );
  };

  const handleCloseLT = () => {
    if (leadership) {
      navigate(`/leadership-teams/${leadership.id}`);
    }
  };

  const saveDeleteLeadership = async () => {
    dispatch(removeLeadershipTeam(leadershipId))
      .then(() => navigate(`/leadership-teams`))
      .catch(error => {
        displayErrorToast(
          error.message,
          ACTIONS.deleteLeadership,
          leadershipId,
        );
      });
  };

  const updateNameDescription = async () => {
    if (
      leadership &&
      (ltName !== leadership.name ||
        infoText !== leadership.info ||
        leadership.deptId !== dept?.id! ||
        (leadership?.tags !== undefined &&
          compareArrays(selectedTags, leadership.tags)))
    ) {
      await dispatch(
        updateLeadershipTeam(
          leadershipId,
          ltName,
          infoText,
          dept?.id!,
          selectedTags,
        ),
      ).catch(e => {
        displayErrorToast(e.message, ACTIONS.editLeadership, leadershipId);
      });
    }
  };

  const saveUpdateLt = async () => {
    updateNameDescription();
    if (!leadership) {
      return;
    }
    await saveUpdateLeadership();
    navigate(`/leadership-teams/${leadershipId}`);
  };

  const saveUpdateLeadership = async () => {
    if (leadership) {
      const result = getMembersChangeSet(
        leadership,
        ltName!,
        infoText!,
        rolesList,
        leadershipMembers!,
        inputRowList,
        RoleType.LT_ROLE,
        users,
      );
      if (!result) {
        return;
      }
      const {
        tempGroup,
        deletedMemberIds,
        newMembers,
        addedRoles,
        changedMembers,
        updatedRoles,
      } = result;
      await Promise.all([
        dispatch(
          createLeadershipMembers(tempGroup, newMembers, addedRoles),
        ).catch(e => {
          displayErrorToast(e.message, ACTIONS.createMember, leadershipId);
        }),
        dispatch(
          updateLeadershipMembers(tempGroup, changedMembers, updatedRoles),
        ).catch(e => {
          displayErrorToast(e.message, ACTIONS.editMember, leadershipId);
        }),
        dispatch(removeLeadershipMembers(tempGroup, deletedMemberIds)).catch(
          e => {
            displayErrorToast(e.message, ACTIONS.deleteMember, leadershipId);
          },
        ),
      ]).catch(e => {
        console.log(e);
      });
      dispatch(reloadTeam(leadershipId));
    }
  };

  if (userStatus.state == ApiState.IDLE) {
    return leadership ? (
      <>
        <Navigation />
        <div className="main-container">
          <div className="top-section">
            <Breadcrumbs
              breadcrumbs={[
                {
                  label: 'Leadership Teams',
                  onClick: function backToLeadership() {
                    navigate('/leadership-teams');
                  },
                },
                {
                  label: leadership?.name,
                },
              ]}
            />
          </div>
          <EditTitle titleText={ltName} setTitleText={setLtName} />

          <EditGroupDepartment
            dept={dept!}
            deptList={deptList}
            setSubDept={setDept}
          />

          <EditDescription infoText={infoText} setInfoText={setInfoText} />

          <MembersContext.Provider value={{ inputRowList, setInputRowList }}>
            <EditMembers members={leadershipMembers} rolesList={rolesList}>
              <Col sm={12} style={{ marginTop: '.5rem' }}>
                <EditTags
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                />
              </Col>
            </EditMembers>
          </MembersContext.Provider>
          <SaveAndCloseButtons
            isDisabled={isDisabled}
            handleCloseGroup={handleCloseLT}
            saveUpdateGroup={saveUpdateLt}
            allowEditGroup={allowLeadershipEdit}
          />
          <DeleteObjectModal
            type="Leadership Team"
            itemName={leadership.name}
            handleDelete={saveDeleteLeadership}
            handleClose={function noRefCheck() {}}
            allowDelete={allowLeadershipEdit}
          />
        </div>
        <Footer></Footer>
      </>
    ) : (
      <PageNotFound></PageNotFound>
    );
  } else {
    return (
      <div className="loading">
        <LoadingIndicator variant={'page'}></LoadingIndicator>
      </div>
    );
  }
};
