export function titleCase(s: string) {
  const exceptions = new Set([
    'LLC',
    'LTD',
    'CSI',
    'USA',
    'CORP',
    '3CI',
    'LLP',
    'NCR',
    'BDO',
    'PA',
    'US',
  ]);
  if (s == 'Chick-fil-A, Inc.') {
    return s;
  }

  return s
    .toLowerCase()
    .split(' ')
    .map(word =>
      exceptions.has(word.toUpperCase())
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.slice(1),
    )
    .join(' ');
}

export function removeCompanySuffix(s: string | undefined) {
  if (!s) {
    return undefined;
  }
  const suffixes = ['LLC', 'LTD', 'CORP', 'LLP', 'LLC.', 'INC', 'INC.', '[.,]'];
  let result = s.toUpperCase();
  for (const suffix of suffixes) {
    result = result.replace(suffix, '');
  }
  result = result.replace(new RegExp('[.,]', 'g'), '');
  return titleCase(result)?.trim();
}
