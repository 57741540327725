export function compareArrays(
  selectedTags: String[],
  podTags: String[],
): boolean {
  if (selectedTags.length !== podTags.length) {
    return true; // Different lengths means they are different
  }
  for (let i = 0; i < selectedTags.length; i++) {
    if (selectedTags[i] !== podTags[i]) {
      return true; // Found a difference
    }
  }
  return false; // Arrays are the same
}
