// unused import statements are contained in commented blocks

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import InitializeApp from 'app/components/app/InitalizeApp';
import { PodsEditProfilePage } from 'app/pages/DeptPods/PodsEditProfilePage';
import { PodsProfilePage } from 'app/pages/DeptPods/PodsProfilePage';
import { GuildEditProfilePage } from 'app/pages/Guilds/GuildEditProfile';
import { GuildProfilePage } from 'app/pages/Guilds/GuildProfilePage';
import Leadership from 'app/pages/Leadership/Leadership';
import { LeadershipEditProfilePage } from 'app/pages/Leadership/LeadershipEditProfile';
import { LeadershipProfilePage } from 'app/pages/Leadership/LeadershipProfilePage';
import PageNotFound from 'app/pages/PageNotFound/PageNotFound';
import ProfilePage from 'app/pages/Profile/ProfilePage';
import SearchPage from 'app/pages/Search/SearchPage';
import SteeringCommittee from 'app/pages/SteeringCommittee/SteeringCommittee';
import { adminAccess } from 'app/utils/hasPermissions/allowFeature';
import { LoadingIndicator } from 'cfa-react-components';
import 'cfa-react-components/dist/styles.min.css';
import { Component } from 'react';
import { Provider } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Admin from './app/pages/Admin/Admin';
import { Pods } from './app/pages/DeptPods/Pods';
import Guilds from './app/pages/Guilds/Guilds';
import Home from './app/pages/Home/Home';
import { persistor, store } from './app/store';
import history from './app/utils/history';
import TagsPage from 'app/pages/Tags/TagsPage';
import CompanyPage from 'app/pages/ExternalCompany/CompanyPage';

// Creates an OktaAuth object for authenticating users against an Okta authorization server
const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/implicit/callback',
  scopes: ['openid', 'profile', 'email'],
});

// Restores the original URI after an Okta authentication flow has occurred
const restoreOriginalUri = (_oktaAuth, originalUri) => {
  console.log(window.location.origin);
  history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  window.location.reload();
};

// Handles scenarios where the user is not authenticated and tries to access a protected resource
// Redirects the user to the login page by setting the window.location to /login.
class App extends Component {
  onAuthRequired() {
    oktaAuth.signInWithRedirect({ originalUri: '/' });
  }

  render() {
    return (
      <div>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <Security
                oktaAuth={oktaAuth}
                onAuthRequired={this.onAuthRequired}
                restoreOriginalUri={restoreOriginalUri}
              >
                <InitializeApp restoreOriginalUri={restoreOriginalUri}>
                  <Routes>
                    <Route path="/" element={<Navigate to={'/home'} />} />
                    <Route path="/home" element={<Home />} />
                    <Route
                      path="/implicit/callback"
                      element={
                        <LoginCallback loadingElement={<LoadingIndicator />} />
                      }
                    />
                    <Route exact path="/pods" element={<Pods />} />
                    <Route path="/company/:companyName" element={<CompanyPage />} />
                    <Route path="/pods/:podId" element={<PodsProfilePage />} />
                    <Route
                      path="/pods/:podId/edit"
                      element={<PodsEditProfilePage />}
                    />
                    <Route
                      exact
                      path="/leadership-teams"
                      element={<Leadership />}
                    />
                    <Route
                      exact
                      path="/profile/:profileId"
                      element={<ProfilePage />}
                    />
                    {adminAccess() ? (
                      <Route exact path="/admin" element={<Admin />} />
                    ) : (
                      <Route path="/error" element={<PageNotFound />} />
                    )}
                    {/* <Route path="/admin" element={<Admin/>} /> */}
                    <Route
                      path="/leadership-teams/:leadershipId"
                      element={<LeadershipProfilePage />}
                    />
                    <Route exact path="/guilds" element={<Guilds />} />
                    <Route
                      path="/guilds/:guildId"
                      element={<GuildProfilePage />}
                    />
                    <Route
                      path="/guilds/:guildId/edit"
                      element={<GuildEditProfilePage />}
                    />
                    <Route
                      exact
                      path="/steering-groups"
                      element={<SteeringCommittee />}
                    />
                    <Route
                      path="/leadership-teams/:leadershipId/edit"
                      element={<LeadershipEditProfilePage />}
                    />
                    <Route path="/search" element={<SearchPage />} />
                    {/* <Route exact path="/steering-groups" element={<SteeringCommittee/>} /> */}

                    <Route path="*" element={<PageNotFound />} />
                    <Route path="/tags/:tagName" element={<TagsPage />} />
                  </Routes>
                </InitializeApp>
              </Security>
            </Router>
          </PersistGate>
        </Provider>
        {/* <Home></Home> */}
      </div>
    );
  }
}

export default App;
